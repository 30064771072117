import { actions } from './actions'
import { api } from '@/shared'
import { message } from 'antd'
import { handbookThunks } from '@/entity/handbook'
import { contractsThunks } from '@/entity'
import { downloadFileByUid } from '@/shared/api/rest/pension/downloadFileByUid'

export const getList = (params, page, size) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleError(null))
			dispatch(actions.toggleLoading(true))
			//dispatch(accidentActions.setParams(params))
			const data = await api.grading.positionEvaluation.list(params, { page, size })
			dispatch(actions.setEvaluations(data))
			dispatch(actions.toggleSuccess(true))
			dispatch(actions.toggleLoading(false))
		} catch (error) {
			dispatch(actions.toggleError(error))
			dispatch(actions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getFormInitialData = () => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const data = await api.grading.positionEvaluation.initialData()

			dispatch(actions.setFormInitialData(data))
			dispatch(actions.toggleLoading(false))
		} catch (error) {
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const calculate = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleError(null))
			dispatch(actions.toggleLoading(true))
			const data = await api.grading.positionEvaluation.calculate(payload)
			dispatch(actions.setCalculation(data))
			dispatch(actions.toggleSuccess(true))
			dispatch(actions.toggleLoading(false))
		} catch (error) {
			dispatch(actions.toggleError(error))
			dispatch(actions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const generatePdf = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(actions.toggleError(null))
			dispatch(actions.toggleLoading(true))
			const data = await api.grading.positionEvaluation.generatePdf(payload)
			//const blob = new Blob([data], { type: data.type });
			const file = URL.createObjectURL(data)
			const a = document.createElement('a')
			a.href = file
			a.download = 'file.pdf'
			document.body.appendChild(a)
			a.click()

			dispatch(actions.toggleSuccess(true))
			dispatch(actions.toggleLoading(false))
		} catch (error) {
			dispatch(actions.toggleError(error))
			dispatch(actions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getOptions = () => {
	return (dispatch, getState) => {
		const {
			calcActivityArea,
			calcOrganizationType,
			calcPositionRegister,
			calcFunctionalDuty,
			calcPositionRegisterHasDutyFunction,
			calcFunctionalBlock,
			calcDutyFunction,
			calcBlockHasCalcDuty,
			calcFactor,
			calcAssessmentCriteria,
			calcBase,
			calcGradeHaveDuty,
			calcGradeLevel,
		} = getState().handbook

		if (!calcActivityArea?.length > 0) dispatch(handbookThunks.getCalcActivityArea())
		if (!calcOrganizationType?.length > 0) dispatch(handbookThunks.getCalcOrganizationType())
		if (!calcPositionRegister?.length > 0) dispatch(handbookThunks.getCalcPositionRegister())
		if (!calcFunctionalDuty?.length > 0) dispatch(handbookThunks.getCalcFunctionalDuty())
		if (!calcPositionRegisterHasDutyFunction?.length > 0)
			dispatch(handbookThunks.getCalcPositionRegisterHasDutyFunction())
		if (!calcFunctionalBlock?.length > 0) dispatch(handbookThunks.getCalcFunctionalBlock())
		if (!calcDutyFunction?.length > 0) dispatch(handbookThunks.getCalcDutyFunction())
		if (!calcBlockHasCalcDuty?.length > 0) dispatch(handbookThunks.getCalcBlockHasCalcDuty())
		if (!calcFactor?.length > 0) dispatch(handbookThunks.getCalcFactor())
		if (!calcAssessmentCriteria?.length > 0) dispatch(handbookThunks.getCalcAssessmentCriteria())
		if (!calcBase?.length > 0) dispatch(handbookThunks.getCalcBase())
		if (!calcGradeHaveDuty?.length > 0) dispatch(handbookThunks.getCalcGradeHaveDuty())
		if (!calcGradeLevel?.length > 0) dispatch(handbookThunks.getCalcGradeLevel())
	}
}
