import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LangFooter from '@/components/LangFooter'
import { HamburgerMenu } from '@app/images'
import { NotifyBadge } from '@/features'
import { ProfilePopup } from '@features/profilePopup'
import { ProfileIcon } from '@app/icons'
import styles from './Header.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { userSelectors } from '@/entity'
import { userActions } from '@/entity/user/model/index'
import { useClickOutside } from '@/shared'
import { useHistory } from 'react-router-dom'
import { Warning } from './Warning'
import { setToLocalStorage } from '@/shared'

export const Header = ({ title, toggleOpen }) => {
	const user = useSelector(userSelectors.user)
	const [showPopup, setShowPopup] = useState(false)
	const ref = useRef(null)

	const handlePopupToggle = useCallback(() => {
		setShowPopup((show) => !show)
	}, [])

	useClickOutside(ref, showPopup, () => setShowPopup(false))

	const dispatch = useDispatch()
	const history = useHistory()

	const {
		t,
		i18n: { language },
	} = useTranslation()

	const handleTabClick = (href, role) => {
		dispatch(userActions.setActiveRole(role))
		history.push(href)
		setToLocalStorage('activeRole', role)
	}

	return (
		<>
			<header className={styles.header}>
				<div className={styles.leftSide}>
					<img className={styles.hamburger} alt="hamburger" src={HamburgerMenu} onClick={toggleOpen} />
					<h1 className={styles.title}>{t(title)}</h1>
				</div>
				{user ? (
					<div className={styles.rightSide}>
						<div className={styles.hideMobile}>
							<LangFooter header dark />
						</div>
						<div className={styles.hideMobile}>
							<NotifyBadge />
						</div>
						<div ref={ref}>
							<div className={styles.popupToggle} onClick={handlePopupToggle}>
								<ProfileIcon className={styles.headerIcon} />
							</div>
							<ProfilePopup show={showPopup} handleTabClick={handleTabClick} />
						</div>
					</div>
				) : (
					<div className={styles.rightSide}>
						<div className={styles.hideMobile}>
							<LangFooter header dark />
						</div>
					</div>
				)}
			</header>
		</>
	)
}
