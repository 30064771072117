import { handbookThunks } from '@/entity/handbook'
import { colContractsActions } from '@/entity'
import { api } from '@/shared'
import { message } from 'antd'

export const getOptions = () => {
	return (dispatch, getState) => {
		const { workTimeCollectiveContract, relaxTimeCollectiveContract } = getState().handbook

		if (!workTimeCollectiveContract?.length > 0) dispatch(handbookThunks.getWorkTime())
		if (!relaxTimeCollectiveContract?.length > 0) dispatch(handbookThunks.getRelaxTime())
	}
}

export const generateXML = (data) => {
	return async (dispatch) => {
		try {
			dispatch(colContractsActions.setColContractLoading(true))
			const contractXml = await api.colContracts.getXml(data)
			dispatch(colContractsActions.setColXML(contractXml))
		} catch (error) {
			dispatch(colContractsActions.toggleError(error))
			message.error(
				'Ошибка получения данных для подписи:' + (error.response ? error.response.data.message : 'Неизвестная ошибка')
			)
		} finally {
			dispatch(colContractsActions.setColContractLoading(false))
		}
	}
}

export const sendSignedColContract = (signedXml) => {
	return async (dispatch) => {
		try {
			dispatch(colContractsActions.setColContractLoading(true))
			const response = await api.colContracts.sendSignedXml(signedXml)
			dispatch(colContractsActions.setColContract(response))
			dispatch(colContractsActions.setColContractSuccess(true))
			message.success('Данные успешно сохранены', 10)
		} catch (error) {
			dispatch(colContractsActions.setColContractLoading(false))
			dispatch(colContractsActions.toggleError(error))
			dispatch(colContractsActions.resetColXml())
			message.error('Ошибка отправки данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(colContractsActions.setColContractLoading(false))
		}
	}
}

export const getColContracts = (page, size, srcText) => {
	return async (dispatch) => {
		try {
			dispatch(colContractsActions.toggleError(null))
			dispatch(colContractsActions.toggleLoading(true))
			const response = await api.colContracts.getContracts({ page, size, srcText })
			dispatch(colContractsActions.setColContracts(response))
			dispatch(colContractsActions.toggleSuccess(true))
		} catch (error) {
			dispatch(colContractsActions.toggleError(error))
			dispatch(colContractsActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(colContractsActions.toggleLoading(false))
		}
	}
}

export const getColContract = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(colContractsActions.toggleError(null))
			dispatch(colContractsActions.toggleLoading(true))
			const response = await api.colContracts.getContract(externalId)
			dispatch(colContractsActions.setColContract(response))
			dispatch(colContractsActions.toggleSuccess(true))
		} catch (error) {
			dispatch(colContractsActions.toggleError(error))
			dispatch(colContractsActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(colContractsActions.toggleLoading(false))
		}
	}
}
