import React, { useEffect, useState } from 'react'
import { connect, Field as FormikField } from 'formik'
import { Checkbox, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import styles from './HarmfulCheckboxBlock.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { useDebounce, useError } from '@/shared'
import { oppvActions, oppvSelector, oppvThunks } from '@/entity/oppv'
import { useDispatch, useSelector } from 'react-redux'
import { Success } from '@app/icons'
import Input from '@mui/material/Input'
import InputMask from 'react-input-mask'

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
	const { ...other } = props
	return (
		<InputMask
			{...other}
			mask="+7 (999) 999-99-99"
			definitions={{
				'#': /[0-9]/,
			}}
			inputRef={ref}
		/>
	)
})

export const HarmfulCheckboxBlock = connect(
	({ isEdit, additionalContract = false, status = null, annuity, isFormVisible = true, formik: { setFieldValue, values } }) => {
		const { t } = useTranslation()
		const lang = getCurrentLanguage()
		const dispatch = useDispatch()
		const [requestDisable, setRequestDisable] = useState(false)
		const [checkBoxState, setCheckBoxState] = useState(!!values.employeeRequest)
		const [inputValue, setInputValue] = useState('') // состояние для отслеживания ввода пользователя
		const debouncedInputValue = useDebounce(inputValue, 1000) // использование хука useDebounce для создания задержки между запросами
		const { errors, setError } = useError()
		const isSuccess = useSelector(oppvSelector.isSuccess)
		const nameOfBank = useSelector(oppvSelector.oppv)
		const isError = useSelector(oppvSelector.isError)
		const langOption = lang === 'rus' ? 'nameRu' : 'nameKz'

		useEffect(() => {
			setInputValue('') // сброс значения на значение по умолчанию
			if (!isEdit) setFieldValue('employeeAccountNumber', '')
		}, [annuity])

		useEffect(() => {
			dispatch(oppvActions.error(null))
		}, [])

		useEffect(() => {
			dispatch(oppvActions.reset())
			if (debouncedInputValue.length === 20) {
				// проверка, что количество символов равно 20
				// Запрос к API
				dispatch(oppvThunks.getBankByAccount({ account: debouncedInputValue })).then(setError)
			}
		}, [debouncedInputValue]) // вызов useEffect при каждом изменении отфильтрованного значения

		const handleCheckBoxChange = () => {
			const newCheckBoxState = !checkBoxState
			setCheckBoxState(newCheckBoxState)
			setFieldValue('employeeRequest', newCheckBoxState)
			setFieldValue('employeeConsent', newCheckBoxState)
			setFieldValue('employerApprove', newCheckBoxState)
			setFieldValue('employeeIbanRequest', newCheckBoxState)
		}

		useEffect(() => {
			if (isEdit) {
				setRequestDisable(values.employeeRequest)
			}
		}, [])
		return (
			<div className={styles.wrapper}>
				{additionalContract ? t('additional_employeeRequest_1') : t('title_employeeRequest_1')}
				<a href={lang === 'rus' ? '../../../files/rules-rus.docx' : '../../../files/rules-kz.docx'} download>
					{t('title_employeeRequest_2')}
				</a>
				{additionalContract ? t('additional_employeeRequest_3') : t('title_employeeRequest_3')}
				{status && status}
				{
					isFormVisible &&
					<>
						<div className={'flex'}>
							<FormikField name="hrManagerPhone">
								{({ field, meta: { touched, error } }) => (
									<div className={styles.mask}>
										<label>{t('hr_manager_phone')}</label>
										<Input
											classes={{ root: styles.phone__field }}
											fullWidth
											disabled={requestDisable}
											{...field}
											inputComponent={TextMaskCustom}
											placeholder={t('pension.payments.form.placeholder.enter_phone')}
										/>
										<div className={styles.error}>{touched && t(error?.key)}</div>
									</div>
								)}
							</FormikField>
							<FormikField name="employeePhone">
								{({ field, meta: { touched, error } }) => (
									<div className={styles.mask}>
										<label>{t('employee_phone')}</label>
										<Input
											classes={{ root: styles.phone__field }}
											fullWidth
											disabled={requestDisable}
											{...field}
											inputComponent={TextMaskCustom}
											placeholder={t('pension.payments.form.placeholder.enter_phone')}
										/>
										<div className={styles.error}>{touched && t(error?.key)}</div>
									</div>
								)}
							</FormikField>
						</div>
						<div className={styles.checkBlock}>
							<div className="flex-1">
								<FormikField name="employeeRequest">
									{({ field }) => (
										<Checkbox
											{...field}
											label={t('employeeRequest')}
											onChange={handleCheckBoxChange}
											value={checkBoxState}
											disabled={requestDisable}
											labelClassName={styles.labelLabel}
										/>
									)}
								</FormikField>
							</div>
							<div className="flex-1">
								<FormikField name="employeeConsent">
									{({ field }) => (
										<Checkbox
											{...field}
											label={t('employeeConsent')}
											onChange={handleCheckBoxChange}
											value={checkBoxState}
											disabled={requestDisable}
											labelClassName={styles.labelLabel}
										/>
									)}
								</FormikField>
							</div>
							<div className="flex-1">
								<FormikField name="employerApprove">
									{({ field }) => (
										<Checkbox
											{...field}
											label={t('employerApprove')}
											onChange={handleCheckBoxChange}
											value={checkBoxState}
											disabled={requestDisable}
											labelClassName={styles.labelLabel}
										/>
									)}
								</FormikField>
							</div>
							<div className="flex-1">
								<FormikField name="employeeIbanRequest">
									{({ field }) => (
										<Checkbox
											{...field}
											label={t('employeeIban')}
											onChange={handleCheckBoxChange}
											value={checkBoxState}
											disabled={requestDisable}
											labelClassName={styles.labelLabel}
										/>
									)}
								</FormikField>
							</div>
							<div className={styles.iban}>
								<div className={styles.fields}>
									<FormikField name={'employeeAccountNumber'}>
										{({ field, meta: { touched, error } }) => (
											<Field
												hideNumberArrows
												placeholder={t('iban_placeholder', { count: 20 })}
												error={touched && error}
												{...field}
												maxLength={20}
												minLength={20}
												pattern="[A-Za-z0-9]{20}"
												onInput={(e) => {
													let value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase()
													if (value.length > 2 && value.slice(0, 2) !== 'KZ') {
														value = 'KZ' + value.slice(2)
													} else if (value.length <= 2) {
														value = 'KZ'
													}
													e.target.value = value
													setInputValue(value) // обновление состояния при каждом вводе
												}}
												disabled={requestDisable}
											/>
										)}
									</FormikField>
								</div>
								{isSuccess ? (
									<div className={styles.ibanBlock}>
										<Success className={styles.iconSuccess} />
										<div className={styles.nameOfBank}>{nameOfBank?.[langOption]}</div>
									</div>
								) : null}
							</div>
							{errors.image || isError !== null ? <p className={styles.error}>{isError || errors.image}</p> : null}
						</div>
					</>
				}
				<div className={styles.comment}>
					<i>
						{t('important_comment_1')}
						<br />
						{'* '}
						{lang === 'rus' ? (
							<>
								<a href={'../../../files/methodology-rus.docx'} download>
									<i>{t('important_comment_2')}</i>
								</a>
								{t('important_comment_3')}
							</>
						) : (
							<>
								{t('important_comment_2')}
								<a href={'../../../files/methodology-rus.docx'} download>
									<i>{t('important_comment_3')}</i>
								</a>
							</>
						)}
					</i>
				</div>
			</div>
		)
	}
)
