import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from './agreementTable.module.scss'
import { headCells } from './agreementTable.headings'
import { getCurrentLanguage } from '@/i18next'
import { agreementsThunks } from '@/entity'
import { handbookSelectors } from '@/entity/handbook'

const AgreementTable = ({ data, view }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()

	const industryAgreement = useSelector(handbookSelectors.industryAgreement)

	const generateIndustryAgreementString = (items = [industryAgreement], ids = []) => {
		return ids
			.map((id) => {
				const item = items.find((item) => item.code.toString() === id)
				return item ? item[language] : null
			})
			.filter(Boolean) // Оставляем только ненулевые значения
			.join(', ')
	}

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'} width={head.width}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const getRequest = useCallback((externalId) => dispatch(agreementsThunks.getAgreement(externalId)), [dispatch])

	const fastView = (row) => {
		getRequest(row.externalId)
		view(true)
	}

	const getTableRow = useCallback(
		(row, index) => {
			const industryAgreementString = generateIndustryAgreementString(industryAgreement, row.industryCodes)

			return (
				<tr key={index} onClick={() => fastView(row)}>
					<td>{row.number}</td>
					<td>{industryAgreementString}</td>
					<td>{lang === 'rus' ? row.industryAgreementNameRu : row.industryAgreementNameKz || '-'}</td>
					<td>{row.dateFrom}</td>
					<td>{row.dateTo || '-'}</td>
				</tr>
			)
		},
		[lang, t, industryAgreement]
	)

	const tableBody = useMemo(() => data?.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr>{tableHeader}</tr>
				</thead>
				<tbody>{tableBody}</tbody>
			</table>
		</>
	)
}

export default AgreementTable
