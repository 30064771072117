import React, { useMemo } from 'react'
import styles from './DetailedAffairs.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Button } from '@/ui'
import { useTranslation } from 'react-i18next'
import { PersonalData } from '@pages/PersonalAffairs/components/PersonalAffairCard/Info/PersonalData'
import ID from '@pages/PersonalAffairs/components/DetailedAffair/modules/ID/ID'
import Education from '@pages/PersonalAffairs/components/DetailedAffair/modules/Education/Education'
import WorkActivity from '@pages/PersonalAffairs/components/DetailedAffair/modules/WorkActivity/WorkActivity'
import Medicine from '@pages/PersonalAffairs/components/DetailedAffair/modules/Medicine/Medicine'
import Nervous from '@pages/PersonalAffairs/components/DetailedAffair/modules/Nervous/Nervous'
import Narco from '@pages/PersonalAffairs/components/DetailedAffair/modules/Narco/Narco'
import Tubik from '@pages/PersonalAffairs/components/DetailedAffair/modules/Tubik/Tubik'
import StatusCandas from '@pages/PersonalAffairs/components/DetailedAffair/modules/StatusCandas/StatusCandas'
import Corruption from '@pages/PersonalAffairs/components/DetailedAffair/modules/Corruption/Corruption'
import Military from '@pages/PersonalAffairs/components/DetailedAffair/modules/Military/Military'
import { Review } from '@pages/PersonalAffairs/components/DetailedAffair/modules/Review/Review'
import { MedicalCard } from '@pages/PersonalAffairs/components/DetailedAffair/modules/MedicalCard/MedicalCard'

const DetailedAffair = ({ onClose, data, selectedMethod, downloadPDF, isAllData }) => {
	const { t } = useTranslation()
	const { methodInfoData } = data || {}
	const photos = methodInfoData?.find(({ method }) => method.code === '010')?.data.document
	const avatar = (
		photos?.find(({ documentType }) => documentType.code === '002') ||
		photos?.find(({ documentType }) => documentType.code === '001')
	)?.photo

	const findMethodData = (code) => methodInfoData?.find(({ method }) => method.code === code)

	const idData = useMemo(() => (selectedMethod === '001' || !selectedMethod) && findMethodData('001'), [methodInfoData])
	const educationData = useMemo(
		() => (selectedMethod === '002' || !selectedMethod) && findMethodData('002'),
		[methodInfoData]
	)
	const workActivity = useMemo(
		() => (selectedMethod === '007' || !selectedMethod) && findMethodData('007'),
		[methodInfoData]
	)
	const medicineData = useMemo(
		() => (selectedMethod === '009' || !selectedMethod) && findMethodData('009'),
		[methodInfoData]
	)
	const nervousData = useMemo(
		() => (selectedMethod === '004' || !selectedMethod) && findMethodData('004'),
		[methodInfoData]
	)
	const narcoData = useMemo(
		() => (selectedMethod === '005' || !selectedMethod) && findMethodData('005'),
		[methodInfoData]
	)
	const tubikData = useMemo(
		() => (selectedMethod === '006' || !selectedMethod) && findMethodData('006'),
		[methodInfoData]
	)
	const candasData = useMemo(
		() => (selectedMethod === '014' || !selectedMethod) && findMethodData('014'),
		[methodInfoData]
	)
	const corruptionData = useMemo(
		() => (selectedMethod === '016' || !selectedMethod) && findMethodData('016'),
		[methodInfoData]
	)
	const militaryData = useMemo(
		() => (selectedMethod === '017' || !selectedMethod) && findMethodData('017'),
		[methodInfoData]
	)

	const reviewData = useMemo(
		() => (selectedMethod === '018' || !selectedMethod) && findMethodData('018'),
		[methodInfoData]
	)

	const medicalCardData = useMemo(
		() => (selectedMethod === '019' || !selectedMethod) && findMethodData('019'),
		[methodInfoData]
	)

	return (
		<Modal windowClassName={styles.modal}>
			<ModalBackButton onClick={onClose} />
			<div className={`${styles.wrapper} scroll`}>
				<PersonalData
					isAllData={isAllData}
					data={data}
					action={downloadPDF}
					isDownload
					avatar={avatar}
					selectedMethod={selectedMethod}
				/>
				{idData && <ID data={idData} />}
				{educationData && <Education data={educationData} />}
				{workActivity && <WorkActivity data={workActivity} />}
				{medicineData && <Medicine data={medicineData} />}
				{nervousData && <Nervous data={nervousData} />}
				{narcoData && <Narco data={narcoData} />}
				{tubikData && <Tubik data={tubikData} />}
				{candasData && <StatusCandas data={candasData} />}
				{/*Comment 08/12/2023*/}
				{/*{corruptionData && <Corruption data={corruptionData} />}*/}
				{militaryData && <Military data={militaryData} />}

				{reviewData && <Review data={reviewData} />}
				{medicalCardData && <MedicalCard data={medicalCardData} />}
			</div>
			<Button onClick={onClose} buttonClassName={styles.closeButton}>
				{t('close')}
			</Button>
		</Modal>
	)
}

export default DetailedAffair
