import { api, convertDataToSelectData } from '@/shared'
import { message } from 'antd'
import { pensionActions, pensionThunks } from '@/entity'
import { savePension } from '@/shared/api/rest/pension/savePension'
import { actions } from '@/entity/laborRecords/model/actions'
import { getDictionaries } from '@/shared/api/rest/dict/getDictionaries'
export const getPersonList = () => {
	return async (dispatch) => {
		try {
			dispatch(pensionActions.toggleLoading(true))
			const data = await api.pension.getPersonList()
			dispatch(pensionActions.setPersons(data))
			dispatch(pensionActions.toggleSuccess(true))
		} catch (error) {
			dispatch(pensionActions.toggleSuccess(false))
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(pensionActions.toggleLoading(false))
		}
	}
}

export const getHandbook = () => {
	return (dispatch, getState) => {
		const { pensionStatuses, pensionStates } = getState().pension
		if (!pensionStatuses?.length > 0) dispatch(pensionThunks.getPensionStatuses())
		if (!pensionStates?.length > 0) dispatch(pensionThunks.getPensionStates())
	}
}
export const getPensionStatuses = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('status', {}, 'proactive/')
			dispatch(pensionActions.setOptions({ name: 'pensionStatuses', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getPensionStates = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('state', {}, 'proactive/')
			dispatch(pensionActions.setOptions({ name: 'pensionStates', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getApplicationHandbook = () => {
	return (dispatch, getState) => {
		const { pensionPrefExp, pensionExp, pensionDocType } = getState().pension
		if (!pensionPrefExp?.length > 0) dispatch(pensionThunks.getPensionPrefExp())
		if (!pensionExp?.length > 0) dispatch(pensionThunks.getPensionExp())
		if (!pensionDocType?.length > 0) dispatch(pensionThunks.getPensionDocType())
	}
}

export const getPensionPrefExp = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('pref_exp', {}, 'proactive/')
			dispatch(pensionActions.setOptions({ name: 'pensionPrefExp', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getPensionExp = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('exp', {}, 'proactive/')
			dispatch(pensionActions.setOptions({ name: 'pensionExp', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getPensionDocType = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('doc_type', {}, 'proactive/')
			dispatch(pensionActions.setOptions({ name: 'pensionDocType', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getPhoneAndConsent = (pensioner) => {
	return async (dispatch) => {
		try {
			dispatch(pensionActions.toggleLoading(true))
			const response = await api.pension.getPhoneAndConsent(pensioner.iin, pensioner.bin, pensioner.fullName)
			dispatch(
				pensionActions.setPension({
					data: {
						state: '',
						status: '',
					},
					isLoading: false,
					isSuccess: false,
				})
			)
			dispatch(pensionActions.setConsentAndPhoneData(response))
			dispatch(pensionActions.toggleSuccess(true))
		} catch (error) {
			dispatch(pensionActions.toggleLoading(false))
			message.error(error.message ? 'Ошибка получения данных: ' + error.message : 'Неизвестная ошибка')
		} finally {
			dispatch(pensionActions.toggleLoading(false))
		}
	}
}

export const getPensions = (params, page, size) => {
	return async (dispatch) => {
		try {
			dispatch(pensionActions.toggleLoading(true))
			dispatch(pensionActions.setParams(params))
			const data = await api.pension.getAll(params, { page, size })
			dispatch(pensionActions.setPensions(data))
			dispatch(pensionActions.toggleSuccess(true))
		} catch (error) {
			dispatch(pensionActions.toggleSuccess(false))
			message.error(error.message ? 'Ошибка получения данных: ' + error.message : 'Неизвестная ошибка')
		} finally {
			dispatch(pensionActions.toggleLoading(false))
		}
	}
}

export const getPension = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(pensionActions.toggleLoading(true))
			const content = await api.pension.get(externalId)
			dispatch(pensionActions.setPension(content))
		} catch (error) {
			dispatch(pensionActions.toggleSuccess(false))
			message.error(error.message ? 'Ошибка получения данных: ' + error.message : 'Неизвестная ошибка')
		} finally {
			dispatch(pensionActions.toggleLoading(false))
		}
	}
}

export const savePensionReq = (data, onClose) => {
	return async (dispatch) => {
		try {
			dispatch(pensionActions.toggleLoading(true))
			await savePension(data)
			dispatch(pensionActions.toggleSuccessSave(true))
			onClose()
			message.success('Запрос был успешно отправлен!')
		} catch (e) {
			dispatch(pensionActions.toggleSuccessSave(false))
			message.error(e?.response?.data?.message)
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const getPensionXml = (data) => {
	return async (dispatch) => {
		try {
			dispatch(pensionActions.toggleLoading(true))
			const xml = await api.pension.getXml(data)
			dispatch(pensionActions.setPensionXML(xml))
			dispatch(pensionActions.toggleSuccess(true))
		} catch (error) {
			dispatch(pensionActions.toggleSuccess(false))
			message.error(error.message ? 'Ошибка получения данных: ' + error.message : 'Неизвестная ошибка')
		} finally {
			dispatch(pensionActions.toggleLoading(false))
		}
	}
}

export const sendPensions = ({ signedXml, onClose }) => {
	return async (dispatch) => {
		try {
			dispatch(pensionActions.toggleLoading(true))
			const response = await api.pension.sendPension({ data: signedXml })
			dispatch(pensionActions.toggleSuccess(true))
			dispatch(pensionActions.toggleSuccessSave(true))
			onClose()
			message.success('Запрос был успешно отправлен!')
		} catch (error) {
			dispatch(pensionActions.toggleSuccess(false))
			dispatch(pensionActions.toggleSuccessSave(false))
			message.error('Ошибка отправки данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}

export const updateConsentAndPhone = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(pensionActions.toggleLoading(true))
			const response = await api.pension.refreshConsentAndPhone(externalId)
			const consent = {
				consentStatus: {
					code: response.consentData?.responseCode,
					nameKz: response.consentData?.responseNameKz,
					nameRu: response.consentData?.responseNameRu,
				},
				hasConsent: response.consentData?.hasConsent,
				hasPhone: response.phoneData?.hasPhone,
				phoneNumberOrError:
					(response.phoneData?.phoneNumber === null ? '' : response.phoneData?.phoneNumber) +
					(response.phoneData?.errorText === null ? '' : response.phoneData?.errorText),
			}
			dispatch(pensionActions.setConsentStatusPension(consent))
			dispatch(pensionActions.toggleSuccess(true))
		} catch (error) {
			dispatch(pensionActions.toggleLoading(false))
			message.error(error.message ? 'Ошибка получения данных: ' + error.message : 'Неизвестная ошибка')
		} finally {
			dispatch(pensionActions.toggleLoading(false))
		}
	}
}

export const updateConsent = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(pensionActions.toggleLoading(true))
			const response = await api.pension.refreshConsent(externalId)
			const consent = {
				consentStatus: {
					code: response.consentData?.responseCode,
					nameKz: response.consentData?.responseNameKz,
					nameRu: response.consentData?.responseNameRu,
				},
				hasConsent: response.consentData?.hasConsent,
			}
			dispatch(pensionActions.setConsentStatusPension(consent))
			dispatch(pensionActions.toggleSuccess(true))
		} catch (error) {
			dispatch(pensionActions.toggleLoading(false))
			message.error(error.message ? 'Ошибка получения данных: ' + error.message : 'Неизвестная ошибка')
		} finally {
			dispatch(pensionActions.toggleLoading(false))
		}
	}
}
