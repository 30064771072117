import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import moment from 'moment/moment'
import { Accordion, AccordionDetails } from '@mui/material'
import React, { useMemo } from 'react'
import { getCurrentLanguage } from '@/i18next'
import { useTranslation } from 'react-i18next'
import { ContractReviewsItem, RatingCount } from '@/features'
import styles from './Review.module.scss'
import cl from 'classnames'

export const Review = ({ data }) => {
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'

	const { t } = useTranslation()

	return (
		<Accordion className={styles.accordion} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
				className={styles.accordion__summary}
			>
				<Typography>
					<div>
						{data.method[optionLang]}{' '}
						<span className={styles.date__info}>{` - ${t('last_update')}: ${moment(data.requestDate).format(
							'DD.MM.YYYY'
						)}`}</span>
					</div>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{data.data.methodDataError && data.data.methodDataError.code === 'E21' && (
					<div className={`dataGridTable_row__1VYIG Corruption_dataRow__3Ll8R ${styles.table_header}`}>
						<p>{t('social_leave.no_data.review_empty')}</p>
					</div>
				)}
				<div className={styles.wrapper}>
					{
						data?.data?.reviewList &&
						<div className={cl('mt-4', 'mb-2')}>
							<RatingCount array={[
								data?.data?.statisticData?.oneCount,
								data?.data?.statisticData?.twoCount,
								data?.data?.statisticData?.threeCount,
								data?.data?.statisticData?.fourCount,
								data?.data?.statisticData?.fiveCount,
							]} avgObject={{
								mark: data?.data?.statisticData?.avgReviewRating,
								count: data?.data?.statisticData?.totalCount,
							}} />
						</div>
					}

					{
						data?.data?.reviewList?.reviewData?.map((review, index) => {
							let item = {
								rating: review.reviewRating,
								reviewTypeCode: 'employerReview',
								answer: {
									text: review.answerText,
								},
								review: {
									text: review.reviewText,
									entryDate: review.reviewEntryDate,
								},
							}

							let title = `${(lang === 'rus' ? review.organization?.nameRu : review.organization?.nameKz )|| ''} (${t('iin_bin')}: ${review.organization?.bin})`

							return (
								<ContractReviewsItem title={title} item={item} key={index} />
							)
						})
					}
				</div>
			</AccordionDetails>
		</Accordion>
	)
}