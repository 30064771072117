import React, { useMemo, useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { Checkbox } from '@/ui'
import { Error } from '@/ui'
import styles from './style.module.scss'
import { VariableSizeList as List } from 'react-window'

const ITEM_HEIGHT_IN_PX = 42

const Option = ({ code, multiSelect, label, onSelect, actives = [], style, setSize, icon, disabled }) => {
	const ref = useRef()
	const active = useMemo(() => actives.includes(code), [actives, code])

	useEffect(() => ref.current && setSize(ref.current.offsetHeight + 24), [ref])

	const handleClick = useCallback(() => {
		if (disabled) {
			return null
		}
		onSelect(code)
	}, [code, onSelect, disabled])

	return (
		<li className={`${styles.option} ${disabled ? styles.option__disabled : ''}`} onClick={handleClick} style={style}>
			{icon && <div className={styles.icon__wrapper}>{icon}</div>}
			{multiSelect && <Checkbox value={active} />}
			<label ref={ref}>{label}</label>
		</li>
	)
}

export const Options = ({
	options,
	optionObjectKey = null,
	onSelect,
	showItems = 5,
	actives,
	multiSelect,
	multiple = false,
	onBlur,
	optionsStyles = {},
	hideNotFoundError,
}) => {
	const _options = optionObjectKey
		? options
		: options?.sort((a, b) => b.isActual - a.isActual)?.sort((a, b) => a.fndChild - b.fndChild)
	const isOptionsLessThenShowItems = _options.length < showItems
	const listHeight = useMemo(
		() => (!isOptionsLessThenShowItems ? showItems : _options.length) * ITEM_HEIGHT_IN_PX,
		[isOptionsLessThenShowItems, _options.length, showItems]
	)
	const { t } = useTranslation()
	const currentLanguage = getCurrentLanguage()
	const lang = useMemo(() => (currentLanguage === 'rus' ? 'nameRu' : 'nameKz'), [currentLanguage])

	const listRef = useRef()
	const sizeMap = useRef({})
	const setSize = useCallback((index, size) => {
		sizeMap.current = { ...sizeMap.current, [index]: size }
		listRef.current.resetAfterIndex(index)
	}, [])

	const renderRow = ({ index, style }) => {
		const { id, code, icon, isActual, fndChild, ...option } = _options[index]
		return (
			<React.Fragment key={`option-${id}`}>
				<Option
					code={option[optionObjectKey] || code}
					label={option[currentLanguage] || option[lang]}
					onSelect={onSelect}
					multiSelect={multiSelect}
					actives={actives}
					style={style}
					setSize={(ref) => setSize(index, ref)}
					icon={icon}
					disabled={isActual === 0 || fndChild === 1}
				/>
			</React.Fragment>
		)
	}

	const getSize = (index) => sizeMap.current[index] || ITEM_HEIGHT_IN_PX

	return (
		<ul
			className={`${!isOptionsLessThenShowItems ? `${styles.overflow} scroll` : ''} ${styles.options} ${
				multiple ? styles.multiple : ''
			}`}
			style={{ height: listHeight, ...optionsStyles }}
			onBlur={onBlur}
		>
			{_options.length > 0 ? (
				<List
					className="scroll"
					ref={listRef}
					height={listHeight}
					itemCount={_options.length}
					itemSize={getSize}
					onClick
				>
					{renderRow}
				</List>
			) : (
				<>
					{!hideNotFoundError && (
						<p className={styles.error}>
							<Error>{t('no_data_find')}</Error>
						</p>
					)}
				</>
			)}
		</ul>
	)
}
