import React, { useEffect } from 'react'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { CollectiveForm } from './CollectiveForm'
import { ColContractValidationSchema } from '@/entity/collectiveContracts'
import { Formik } from 'formik'
import { agreementsThunks } from '@/entity'
import { useDispatch } from 'react-redux'
import { handbookThunks } from '@/entity/handbook'

export const CollectiveContractForm = ({ isTitle, initialValues, onSubmit, options, onCancel, isEdit }) => {
	const { t } = useTranslation()
	const { files } = initialValues
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(agreementsThunks.getDict())
		dispatch(handbookThunks.getColContractTerms())
	}, [])

	const onSubmitMiddleWare = (data) => {
		onSubmit(data)
	}

	return (
		<div>
			{isTitle ? (
				<Title color={'black'}>
					{isEdit
						? t('contracts_tab.edit_collective_contract')
						: t('contracts_tab.registration_new_collective_contract')}
				</Title>
			) : null}
			<Formik
				validationSchema={ColContractValidationSchema}
				initialValues={initialValues}
				onSubmit={onSubmitMiddleWare}
			>
				{(props) => {
					return <CollectiveForm options={options} onCancel={onCancel} files={files}></CollectiveForm>
				}}
			</Formik>
		</div>
	)
}
