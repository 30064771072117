import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentLanguage } from '@/i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { userSelectors } from '@/entity'
import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { sickLeaveThunks } from '@/entity/sickLeave'
import { Table, TableColumn } from '@/components/Table'
import { HrPagination } from '@/components/Pagination'
import { LoadingBlocker } from '@/components/Loader'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import cl from 'classnames'
import styles from './SickLeave.module.scss'
import { View, Upload, InfoGreen } from '@/app/icons'

import { SendModal, ViewModal } from '@/features'
import dayjs from 'dayjs'
import { Icon } from '@/components/Icon'
import { Alert } from '@/components/Alert/Alert'

const queryInitialState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

const TYPES = {
	VIEW: 'view',
	SEND: 'send',
}

const initialState = {
	item: null,
	type: {
		[TYPES.SEND]: false,
		[TYPES.VIEW]: false,
	},
}

export const SickLeave = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { role, sickLeave } = useSelector((state) => ({
		role: userSelectors.activeRole(state),
		sickLeave: state.sickLeave.sickLeave,
	}))

	const [queryParams, setQueryParams] = useState(queryInitialState)

	const [modalData, setModalData] = useState(initialState)

	useEffect(() => {
		if (role) {
			getSickLeave(queryParams)
		}
	}, [role])

	const getSickLeave = (params) => {
		let currentType = {
			ROLE_EMPLOYER: 'employer',
			ROLE_EMPLOYEE: 'employee',
		}

		let body = {
			type: currentType[role],
			params: {
				page: params?.page,
				size: params?.pageSize,
			},
		}

		dispatch(sickLeaveThunks.getSickData(body))
	}

	const employerColumns = [
		{
			title: t('worker_fullname'),
			key: 'fio',
		},
		{
			title: t('worker_iin'),
			key: 'iin',
		},
		{
			title: t('sickLeave.open_date'),
			key: 'sinceReleasWrk',
			render: (item, record) => record?.total?.sinceReleasWrk,
		},
		{
			title: t('sickLeave.closed_date'),
			key: 'tillReleasWrk',
			render: (item, record) => record?.total?.tillReleasWrk,
		},
		{
			title: t('contracts_tab.status'),
			key: 'status',
			render: (item, record) => (
				<span className={cl(styles[`status_${record?.total?.status?.value}`])}>{record?.total?.status?.label}</span>
			),
		},
	]

	const employeeColumns = [
		{
			title: t('sickLeave.med_org'),
			key: 'nameOfMo',
			render: (item, record) => record?.nameOfMo,
		},
		{
			title: t('sickLeave.open_date'),
			key: 'sinceReleasWrk',
			render: (item, record) => record?.total?.sinceReleasWrk,
		},
		{
			title: t('sickLeave.closed_date'),
			key: 'tillReleasWrk',
			render: (item, record) => record?.total?.tillReleasWrk,
		},
		{
			title: t('contracts_tab.status'),
			key: 'status',
			render: (item, record) => (
				<span className={cl(styles[`status_${record?.total?.status?.value}`])}>{record?.total?.status?.label}</span>
			),
		},
		{
			title: t('contracts_tab.action'),
			key: 'action',
			render: (item, record) => {
				let actions = [
					{ icon: <Icon component={View} className={styles.icon} />, title: t('actions.view'), action: TYPES.VIEW },
					{
						icon: <Icon component={Upload} className={styles.icon} />,
						title: t('actions.sendEmployer'),
						action: TYPES.SEND,
					},
				]

				return (
					<DropDownMenu
						className={cl(styles.dropdown)}
						title={t('select_pls')}
						items={actions}
						onItemClick={(mode) => handleClickItem(record, mode)}
						onButtonClick={(e) => e.stopPropagation()}
					/>
				)
			},
		},
	]

	const columns = {
		ROLE_EMPLOYER: employerColumns,
		ROLE_EMPLOYEE: employeeColumns,
	}

	const data = useMemo(() => {
		const res = sickLeave.data?.content?.map((item) => {
			const sortedChunck = item?.chunck?.sort((a, b) => {
				let status = parseInt(a.status) - parseInt(b.status)
				if (status !== 0) {
					return status
				}

				return dayjs(a).toString() - dayjs(b).toString()
			})

			const openedDate = sortedChunck?.find((chunck) => chunck.status === '1')

			const continueDate = sortedChunck?.find((chunck) => chunck.status === '2')
			const closedDate = sortedChunck?.find((chunck) => chunck.status === '3')

			const statusData = closedDate || continueDate || openedDate

			const currentStatus = {
				1: t('sickLeave.open'),
				2: t('sickLeave.continue'),
				3: t('sickLeave.close'),
				4: t('sickLeave.deleted'),
			}

			return {
				...item,
				chunck: sortedChunck,

				total: {
					sinceReleasWrk: statusData?.sinceReleasWrk,
					tillReleasWrk: statusData?.tillReleasWrk,
					status: {
						label: currentStatus[statusData?.status],
						value: statusData?.status,
					},
					dateOfIssue: statusData?.dateOfIssue,
					startWrk: statusData?.startWrk,
					conclMKB10: item?.conclMKB10,
					spcifyTempDis: statusData?.spcifyTempDis,
					typeRegime: statusData?.typeRegime,
					mkb10: statusData?.mkb10,
					number: statusData?.number,
				},
			}
		})

		return res
	}, [sickLeave.data?.content, t])

	const handleChangePage = (e, page) => {
		setQueryParams({ ...queryParams, page: page - 1 })

		let body = {
			...queryParams,
			page: page - 1,
		}

		getSickLeave(body)
	}

	const handleChangeRowsPerPage = (size) => {
		setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

		let body = {
			...queryParams,
			page: 0,
			pageSize: size,
		}

		getSickLeave(body)
	}

	const handleClickItem = (item, type) => {
		setModalData((prevState) => ({
			...prevState,
			item: item,
			type: {
				...prevState,
				[type]: true,
			},
		}))
	}

	const handleUpload = () => {
		setModalData((prevState) => ({
			...prevState,
			type: {
				...prevState.type,
				[TYPES.SEND]: true,
			},
		}))
	}

	const handleBack = () => {
		setModalData((prevState) => ({
			...prevState,
			type: {
				[TYPES.SEND]: false,
				[TYPES.VIEW]: !!prevState.type[TYPES.VIEW],
			},
		}))
	}

	const handleCloseModal = () => {
		setModalData(initialState)
	}

	const alertText = {
		ROLE_EMPLOYEE: t('sickLeave.employee_alert'),
		ROLE_EMPLOYER: t('sickLeave.employer_alert'),
	}

	return (
		<>
			<RedirectUnAuthUser>
				<ProfileTemplate title={'sickLeave.title'}>
					<div className={cl('mb-4')}>
						<Alert text={alertText[role]} icon={<InfoGreen/>} />
					</div>
					<Table
						tableName="sickLeave"
						data={data || []}
						emptyPlaceholder="-"
						onRowClick={(e, record) => {
							e.stopPropagation()

							const acceptArray = ['fio', 'iin', 'status', 'nameOfMo', 'sinceReleasWrk', 'tillReleasWrk']

							if (acceptArray.includes(e.target.dataset?.key)) {
								handleClickItem(record, TYPES.VIEW)
							}
						}}
					>
						{(columns?.[role] || []).map((item) => (
							<TableColumn
								key={item.key}
								dataKey={item.key}
								heading={item.title}
								renderCell={(cell, record) => (item.render ? item.render(cell, record) : cell)}
							/>
						))}
					</Table>

					<div style={{ margin: '36px 34px 0 0' }}>
						<HrPagination
							id="hr-pagination"
							rowsPerPageitems={queryParams.rowsPerPage}
							rowsPerPage={queryParams.pageSize}
							numberOfElements={sickLeave?.data?.page?.numberOfElements}
							totalElements={sickLeave?.data?.page?.totalElements}
							totalPages={sickLeave?.data?.page?.totalPages}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							page={queryParams.page}
						/>
					</div>

					{modalData.type[TYPES.VIEW] && (
						<ViewModal onClose={handleCloseModal} item={modalData.item} onUpload={handleUpload} />
					)}

					{modalData.type[TYPES.SEND] && (
						<SendModal onClose={handleCloseModal} item={modalData.item} onBack={handleBack} />
					)}
				</ProfileTemplate>
			</RedirectUnAuthUser>

			{sickLeave.loading && <LoadingBlocker />}
		</>
	)
}
