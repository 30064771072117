import React, { useEffect, useMemo, useState } from 'react'
import SmallButton from '@/components/entrance/SmallButton'
import { useHistory } from 'react-router-dom'
import { setToLocalStorage } from '@/shared'
import s from './ChooseRolePage.module.scss'
import { BackButton } from '@/components/BackButton'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBlocker } from '@/components/Loader'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { Warning } from './Warning'
import PreHeader from '@/components/home/components/PreHeader'
import Notice from '@pages/ChooseRole/Notice/Notice'
import { notificationsSelectors, notificationsThunks, userActions } from '@/entity'

export const ChooseRolePage = () => {
	const {
		t,
		i18n: { language, changeLanguage },
	} = useTranslation()
	const storedLanguage = localStorage.getItem('lang')
	useEffect(() => {
		if (storedLanguage && storedLanguage !== language) {
			changeLanguage(storedLanguage)
		}
	}, [])

	const history = useHistory()
	const dispatch = useDispatch()
	const notification = useSelector(notificationsSelectors.notificationsAll)
	let employeeNotifs = notification?.employeeNotifs
	let employerNotifs = notification?.employerNotifs
	/*	const [enableModal, setEnableModal] = useState()
	useMemo(() => setEnableModal(process.env.REACT_APP_MODAL_INFO), [])
	const [isOpenInfo, setIsOpenInfo] = useState(true)
	useEffect(() => {
		if (enableModal === 'false') {
			setIsOpenInfo(false)
		}
	}, [enableModal])*/

	const onRedirect = (role) => {
		setToLocalStorage('activeRole', role)
		dispatch(userActions.setActiveRole(role))
		history.push(role === 'ROLE_EMPLOYER' ? '/employer' : '/worker')
	}

	const profile = useSelector((state) => state.user.user)
	const error = useSelector((state) => state.auth.error)

	useEffect(() => {
		if (error) {
			message.error(error.response ? error.response.data.message : error.message || 'Неизвестная ошибка')
		}
	}, [error])

	useEffect(() => {
		if (profile) {
			dispatch(notificationsThunks.getAll())
		}
	}, [profile])

	const loading = !Boolean(profile)



	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<div className={s.container}>
				<div className={s.backBlock}>
					{!Boolean(profile) && !error && <LoadingBlocker />}
					<BackButton to={'/'} />
				</div>
				<div className={s.wrapper}>
					{/*Закомментировано 20.06.2023*/}
					{/*раскомментировать в случае необходимости*/}
					{/*				{isOpenInfo && (
					<Notification
						handleProcess={setIsOpenInfo.bind(null, false)}
						handleCancel={setIsOpenInfo.bind(null, false)}
					/>
				)}*/}
					<div className={s.left}>
						<SmallButton buttondisable={loading ? 1 : 0} onClick={() => onRedirect('ROLE_EMPLOYER')}>
							{t('employer')}
						</SmallButton>
						{profile ? (
							<Notice
								notify={employerNotifs}
								profile={profile}
								employer={true}
							></Notice>
						) : null}
					</div>
					<div className={s.right}>
						<SmallButton buttondisable={loading ? 1 : 0} onClick={() => onRedirect('ROLE_EMPLOYEE')}>
							{t('employee')}
						</SmallButton>
						{profile ? <Notice notify={employeeNotifs} profile={profile} employer={false}></Notice> : null}
					</div>
				</div>
			</div>
		</>
	)
}
