import { handbookThunks } from '@/entity/handbook'
import { companyActions } from '@/entity/company'

export const adjustCompanyOptions = () => {
	return (dispatch, getState) => {
		const state = getState()
		const company = state.company.company

		dispatch(companyActions.setCompaniesFields({ email: state.company.company.email }))
		dispatch(
			getCompanyOptions({
				ddistrictCode: company.ddistrictCode,
				dregionCode: company.dregionCode,
			})
		)
	}
}

export const getCompanyOptions = ({ ddistrictCode, dregionCode }) => {
	return (dispatch) => {
		if (ddistrictCode) dispatch(handbookThunks.getRegions({ code: ddistrictCode }))
		if (dregionCode) dispatch(handbookThunks.getPopulatedAreas({ code: dregionCode }))
	}
}

export const getOptions = () => {
	return (dispatch, getState) => {
		const { ownerships, okeds, districts } = getState().handbook
		if (!ownerships?.length > 0) dispatch(handbookThunks.getOwnerships())
		if (!okeds?.length > 0) dispatch(handbookThunks.getOkeds())
		if (!districts?.length > 0) dispatch(handbookThunks.getDistrictsAuth())
	}
}
