export const isLoading = (state) => state.accidents.isLoading
export const isSuccess = (state) => state.accidents.isSuccess
export const isLoadingSend = (state) => state.accidents.isLoadingSend
export const isSuccessSend = (state) => state.accidents.isSuccessSend
export const error = (state) => state.accidents?.error
export const accidents = (state) => state.accidents.accidents
export const selectedAccidents = (state) => state.accidents.selectedAccidents
export const reportXml = (state) => state.accidents.reportXml
export const accident = (state) => state.accidents.accident
export const person = (state) => state.accidents.person
