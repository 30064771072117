import React, { isValidElement, useMemo, useState } from 'react'
import { Edit as EditIcon, Remove as RemoveIcon, StaffChevronDown, StaffChevronUp, View as ViewIcon } from '@app/images'
import styles from './TableTreeView.module.scss'
import { headCells } from './tableTreeView.headings'
import { useTranslation } from 'react-i18next'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useDispatch, useSelector } from 'react-redux'
import { staffingActions, staffingSelectors, staffingThunks } from '@/entity/staffing'
import { getCurrentLanguage } from '@/i18next'
import { formatNumber } from '@/utils'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { Popover } from 'antd'
import { Arrow } from '@app/icons'
import dropdownStyles from '@/components/DropdownMenu/DropdownMenu.module.scss'
import './TableThreeView.css'

const TableTreeView = ({
	node,
	staffType,
	setActiveStructId,
	setActions,
	setRowStruct,
	parentId,
	setParentStructureId,
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const isOpen = useSelector((state) => state.staffing.openedChildsIds.includes(node.externalId))
	const { staffingGrants } = useSelector((state) => ({
		staffingGrants: state.administration.staffingGrants,
	}))
	const [actionMenuItems, setActionMenuItems] = useState([])

	/**
	 * Вычисление сумм по рабочим местам
	 *
	 * @param {Object} node - Структурное подразделение
	 * @returns {Object} - Сумма Штатных ед., Оклад, Надбавка/Доплата, Оклад факт
	 */
	const total = useMemo(() => {
		const initialSums = {
			staffUnitsCount: 0,
			grossSalary: 0,
			salarySupplement: 0,
			factSalary: 0,
		}

		return (node.positionGroups ?? []).reduce((acc, positionGroup) => {
			;(positionGroup.workPlaces ?? []).forEach((workplace) => {
				acc.staffUnitsCount += workplace.staffUnitsCount ?? 0
				acc.grossSalary += workplace.grossSalary ?? 0
				acc.salarySupplement += workplace.salarySupplement ?? 0
				acc.factSalary += workplace.factSalary ?? 0
			})
			return acc
		}, initialSums)
	}, [node])

	const showDeptType = (code) => {
		const deptType = staffType.find((item) => item.code === code)
		return deptType ? deptType[lang] : null
	}

	const handleToggleClick = (e) => {
		e.stopPropagation()
		const id = node.externalId
		if (node.childs?.length > 0) {
			if (isOpen) {
				dispatch(staffingActions.removeOpenedChildId(id))
			} else {
				dispatch(staffingActions.addOpenedChildId(id))
			}
		}
	}

	const onToggleAction = (action, value, row) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
		setActiveStructId(row.externalId)
		setRowStruct(row)

		dispatch(staffingThunks.getStatInfo({externalId: row.externalId}))
	}

	const onActionMenuChoose = (mode, row) => {
		onToggleAction(mode, true, row)
	}

	let actions = [
		{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
		...(staffingGrants?.data?.stRegistration && node.level !== 0
			? [
				{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
				{ icon: RemoveIcon, title: t('actions.remove'), action: 'remove' },
			]
			: []),
	]

	const actionContent = useMemo(() => {
		let list = actions?.map((item, index) => (
			<li key={item?.action} onClick={() => {
				setParentStructureId(parentId)
				onActionMenuChoose(item.action, node)
			}}>
				<img src={item.icon} alt="menu-icon" />
				{item?.title}
			</li>
		))
		return (
			<Popover
				placement="bottomRight"
				zIndex={50}
				trigger='click'
				overlayClassName={'actions'}
				content={(
					<ul className={`${styles.list} `}>
						{list}
					</ul>
				)}
			>
				<button type={'button'} className={`${dropdownStyles.dropdownButton}`}>
					{t('select_pls')}<Arrow className={dropdownStyles.icon} />
				</button>
			</Popover>
		)
	}, [actions])

	return (
		<>
			<tr onClick={handleToggleClick}>
				<td
					style={{
						paddingLeft: `${node.level * 10}px`,
					}}
					className={styles['td-cursor-pointer']}
				>
					{node.childs?.length > 0 ? (
						isOpen ? (
							<img className={styles.treeView} src={StaffChevronUp} alt="minus" />
						) : (
							<img className={styles.treeView} src={StaffChevronDown} alt="plus" />
						)
					) : null}
				</td>
				<td
					style={{
						paddingLeft: `${node.level * 10 + 10}px`, // всегда добавляем дополнительный отступ
					}}
					className={`${styles['td-cursor-pointer']} ${styles['td-padding-left']}`}
				>
					{lang === 'rus' ? node.dept.nameRu : node.dept.nameKz}
				</td>
				<td>{showDeptType(node.deptTypeCode)}</td>
				<td>{node.dept.code}</td>
				<td>{total.staffUnitsCount}</td>
				<td>{formatNumber(total.grossSalary || '')}</td>
				<td>{formatNumber(total.salarySupplement || '')}</td>
				<td>{formatNumber(total.factSalary || '')}</td>
				<td>
					{actionContent}
				</td>
			</tr>
			{isOpen &&
				node.childs?.length > 0 &&
				node.childs.map((child) => (
					<TableTreeView
						key={child.externalId}
						node={{ ...child, level: node.level + 1 }}
						staffType={staffType}
						setActiveStructId={setActiveStructId}
						setActions={setActions}
						setRowStruct={setRowStruct}
						parentId={node.externalId}
						setParentStructureId={setParentStructureId}
					/>
				))}
		</>
	)
}

export const TreeView = ({
	staffing,
	staffType,
	setActiveStructId,
	setActions,
	setRowStruct,
	setParentStructureId,
}) => {
	const { t } = useTranslation()
	const isLoading = useSelector(staffingSelectors.isLoading)
	const error = useSelector(staffingSelectors.error)

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	return (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>
				{staffing?.currVersion && !isLoading ? (
					staffing.childs.map((child) => (
						<TableTreeView
							parentId={staffing.externalId}
							key={child.externalId}
							node={{ ...child, level: 0 }}
							staffType={staffType}
							setActiveStructId={setActiveStructId}
							setActions={setActions}
							setRowStruct={setRowStruct}
							setParentStructureId={setParentStructureId}
						/>
					))
				) : (
					<tr>
						<td colSpan={8} className={styles.messageCol}>
							{isLoading && (
								<LoadingScreen>
									<Title>
										{t('staff.stop')} <br />
										{t('labor_records.loading')}
									</Title>
								</LoadingScreen>
							)}
							{!isLoading && !staffing && <div className={styles.tableLoading}>{t('staff.info_no_staff')}</div>}
							{!isLoading && error && <div className={styles.error}>{t('has_error')}</div>}
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}
