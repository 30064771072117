export const positionsByKnz = (state) => state.handbook.positionsByKnz
export const positionsByKnzFull = (state) => state.handbook.positionsByKnzFull
export const positions = (state) => state.handbook.positions
export const jobTypes = (state) => state.handbook.jobTypes
export const remoteWorkTypes = (state) => state.handbook.remoteWorkTypes
export const workingTimeModes = (state) => state.handbook.workingTimeModes
export const countries = (state) => state.handbook.countries
export const regions = (state) => state.handbook.regions
export const districts = (state) => state.handbook.districts
export const populatedAreas = (state) => state.handbook.populatedAreas
export const professionalSkills = (state) => state.handbook.professionalSkills
export const personalQualities = (state) => state.handbook.personalQualities
export const contractStatuses = (state) => state.handbook.contractStatuses
export const contractTypes = (state) => state.handbook.contractTypes
export const contractStates = (state) => state.handbook.contractStates
export const ownerships = (state) => state.handbook.ownerships
export const singleOptions = (state) => state.handbook.singleOptions
export const contractTerms = (state) => state.handbook.contractTerms
export const okeds = (state) => state.handbook.okeds
export const socialLeaveTypes = (state) => state.handbook.socialLeaveTypes
export const laborType = (state) => state.handbook.laborType
export const workActivityTypes = (state) => state.handbook.workActivityTypes
export const workCondition = (state) => state.handbook.workCondition
export const extraGuaranteesCollectiveContract = (state) => state.handbook.extraGuaranteesCollectiveContract
export const unionTypeCollectiveContract = (state) => state.handbook.unionTypeCollectiveContract
export const all = (state) => state.handbook
export const workTimeCollectiveContract = (state) => state.handbook.workTimeCollectiveContract
export const relaxTimeCollectiveContract = (state) => state.handbook.relaxTimeCollectiveContract
export const workActivityTypeGroups = (state) => state.handbook.workActivityTypeGroups
export const nonresidentAttractingBasis = (state) => state.handbook.nonresidentAttractingBasis
export const nonresidentEducation = (state) => state.handbook.nonresidentEducation
export const ccEnterpriseType = (state) => state.handbook.ccEnterpriseType
export const ccOwnershipType = (state) => state.handbook.ccOwnershipType
export const staffType = (state) => state.handbook.staffType
export const staffStatus = (state) => state.handbook.staffStatus
export const staffEmpStatus = (state) => state.handbook.staffEmpStatus
export const unionType = (state) => state.handbook.unionType
export const okedsTop = (state) => state.handbook.okedsTop
export const accidentSendStatus = (state) => state.handbook.accidentSendStatus
export const calcActivityArea = (state) => state.handbook.calcActivityArea
export const calcAssessmentCriteria = (state) => state.handbook.calcAssessmentCriteria
export const calcBlockHasCalcDuty = (state) => state.handbook.calcBlockHasCalcDuty
export const calcDutyFunction = (state) => state.handbook.calcDutyFunction
export const calcFactor = (state) => state.handbook.calcFactor
export const calcFunctionalBlock = (state) => state.handbook.calcFunctionalBlock
export const calcFunctionalDuty = (state) => state.handbook.calcFunctionalDuty
export const calcOrganizationType = (state) => state.handbook.calcOrganizationType
export const calcPositionRegister = (state) => state.handbook.calcPositionRegister
export const calcPositionRegisterHasDutyFunction = (state) => state.handbook.calcPositionRegisterHasDutyFunction
export const calcBase = (state) => state.handbook.calcBase
export const calcGradeHaveDuty = (state) => state.handbook.calcGradeHaveDuty
export const calcGradeLevel = (state) => state.handbook.calcGradeLevel
export const genders = (state) => state.handbook.genders
export const industryAgreement = (state) => state.handbook.industryAgreement
export const reviewType = (state) => state.handbook.reviewType
export const serviceBinGrantType = (state) => state.handbook.serviceBinGrantType

export const laborMobilityCenterCategory = (state) => state.handbook.laborMobilityCenterCategory
export const establishedPostPositionCategoryRelation = (state) => state.handbook.establishedPostPositionCategoryRelation
export const colContractTerms = (state) => state.handbook.colContractTerms
export const dColContractDate = (state) => state.handbook.dColContractDate
export const icd = (state) => state.handbook.icd
