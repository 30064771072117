import React, { useEffect, useState } from 'react'
import { useDigitalSign } from '@/shared'
import { initialIndustryAgreement } from '@/entity/industryAgreement/model/reducer'
import { IndustryAgreement } from './form/IndustryAgreement'
import { useDispatch, useSelector } from 'react-redux'
import { agreementsActions, agreementsSelectors, agreementsThunks } from '@/entity'
import { save } from '@/entity/industryAgreement/model/thunks'
import { Button, ModalTemplate } from '@/ui'
import { Trans, useTranslation } from 'react-i18next'
import cl from 'classnames'

let initialState = {
	isVisible: false,
	data: null,
}

export const CreateOrEdit = ({ agreement, editModalData, fetchData, onClose, onEditSuccess }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const agreementXml = useSelector(agreementsSelectors.agreementXml)
	const isSuccess = useSelector(agreementsSelectors.isSuccessIndustryAgreement)
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()

	const [modalData, setModalData] = useState(initialState)

	const onClear = () => {
		onClearPayload()
		dispatch(agreementsActions.resetAgreementXml())
		dispatch(agreementsActions.setAgreementSuccess(false))
	}

	useEffect(() => {
		if (successPayload) {
			dispatch(agreementsThunks.sendAgreement(successPayload))
			onClearPayload()
		}
	}, [successPayload])

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	useEffect(() => agreementXml && onSign(agreementXml), [agreementXml])

	useEffect(() => {
		if (isSuccess) {
			onClear()
			fetchData()
			onClose()
			if (editModalData.isVisible) {
				onEditSuccess()
			}
		}
		return onClear()
	}, [isSuccess])

	const onConfirm = (data) => {
		setModalData({
			isVisible: true,
			data: data,
		})
	}

	const onSubmit = () => {
		if(editModalData.type === 'create' || editModalData.type === 'add'){
			dispatch(agreementsThunks.save(modalData.data))
		} else if(editModalData.type === 'edit'){
			dispatch(agreementsThunks.edit(modalData.data))
		}
	}

	const formInitialState = () => {
		const {files ,...agreements} = agreement || {}

		let types = {
			create: initialIndustryAgreement,
			add: agreements,
			edit: agreement,
		}

		return types[editModalData?.type] || initialIndustryAgreement
	}

	return (
		<>
			{
				modalData.isVisible &&
				<ModalTemplate onClose={() => setModalData(initialState)} priority={2}>
					<div style={{ maxWidth: 400 }}>
						<h3 className={'text-center'}>{t('attention')}</h3>
						<h4 className={'text-center'}>
							<Trans
								i18nKey={'industryAgreement.confirm_modal'}
								components={{ br: <br /> }}
							/>
						</h4>

						<div className={cl('flex', 'flex-column', 'align-center', 'gap-2', 'mt-4')}>
							<div>
								<Button onClick={onSubmit}>
									{t('save')}
								</Button>
							</div>

							<div className={cl('mt-3')}>
								<Button type='bare' onClick={() => setModalData(initialState)}>
									{t('cancel')}
								</Button>
							</div>
						</div>
					</div>
				</ModalTemplate>
			}

			<IndustryAgreement
				initialValues={formInitialState()}
				onCancel={onClose}
				onSubmit={onConfirm}
				editModalData={editModalData}
			></IndustryAgreement>
		</>
	)
}
