import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearUserData, cookies } from '@/shared'
import { authThunks } from '@/app/store/auth'
import { dictThunks } from '@app/store/dict'
import { useHistory, useLocation } from 'react-router-dom'

export function useQuery() {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

export const AuthorisePassport = ({ children }) => {
	let query = useQuery()

	const dispatch = useDispatch()
	const history = useHistory()
	useEffect(() => {
		const SSO_PASSPORT = process.env.REACT_APP_PASSPORT
		const cookie = cookies.get(SSO_PASSPORT, true)
		if (cookie.key) {
			if (query.get('forceRefresh')) {
				return dispatch(
					authThunks
						.getCurrentUser(
							{
								data: { name: cookie.key, value: cookie.value },
								history,
							},
							{ forceRefresh: true },
							() => {
								dispatch(dictThunks.getDictionaryVersion())
							}
						)
				)
			}
			dispatch(
				authThunks.getCurrentUser({
					data: { name: cookie.key, value: cookie.value },
					history,
				})
			)
				.then(() => dispatch(dictThunks.getDictionaryVersion()))
				.catch((error) => {
					console.log(error)
				})
		} else {
			clearUserData()
		}
	}, [dispatch, history])

	return <>{children}</>
}
