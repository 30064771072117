import { actions } from './actions'
import { api, convertDataToSelectData } from '@/shared'
import { getDictionaries } from '@/shared/api/rest/dict/getDictionaries'
import { serviceBinGrantType } from '@/entity/handbook/selectors'

export const filterKato = (code) => {
	async function getFilterContent(code) {
		const content = await getDictionaries('katoOptimal')

		if (code === undefined || code === '') {
			const filterParent = [{ id: 1 }]
			return content.filter((item) => item.parentId === filterParent[0].id)
		}

		const filterParent = content.find((item) => item.code === code)
		return content.filter((item) => item.parentId === filterParent.id)
	}

	return getFilterContent(code)
}

export const filterLeafItems = (code) => {
	async function getFilterContent(code) {
		const content = await getDictionaries('katoOptimal')
		function filterLeafNodes(parentId) {
			return content
				.filter((item) => item.parentId === parentId)
				.reduce((leafItems, currentItem) => {
					const children = filterLeafNodes(currentItem.id)
					if (children.length === 0) {
						leafItems.push(currentItem)
					} else {
						leafItems.push(...children)
					}
					return leafItems
				}, [])
		}
		if (code === undefined || code === '') {
			return filterLeafNodes(1)
		}
		const parentItem = content.find((item) => item.code === code)
		return parentItem ? filterLeafNodes(parentItem.id) : []
	}
	return getFilterContent(code)
}

export const filterOneKato = (code) => {
	async function getFilterContent(code) {
		const content = await getDictionaries('katoOptimal')

		if (code === undefined || code === '') {
			const filterParent = [{ id: 1 }]
			return content.filter((item) => item.parentId === filterParent[0].id)
		}

		return content.find((item) => item.code === code)
	}

	return getFilterContent(code)
}
export const getPositions = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('establishedPost', { size: 9999 })
			dispatch(actions.setOptions({ name: 'positions', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getOkeds = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('oked', { size: 99999 })
			dispatch(actions.setOptions({ name: 'okeds', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getWorkCondition = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('workCondition')
			dispatch(actions.setOptions({ name: 'workCondition', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}


export const getDColContractDate = () => {
	return async (dispatch) => {
		try {
			const data = await getDictionaries('dColContractDate')
			dispatch(actions.setOptions({ name: 'dColContractDate', data: convertDataToSelectData(data) }))
		} catch (error) {}
	}
}

export const getUnionTypeCollectiveContract = () => {
	return async (dispatch) => {
		try {
			const result = await getDictionaries('unionTypeCollectiveContract')
			dispatch(actions.setOptions({ name: 'unionTypeCollectiveContract', data: convertDataToSelectData(result) }))
		} catch (error) {}
	}
}

export const getExtraGuaranteesCollectiveContract = () => {
	return async (dispatch) => {
		try {
			const result = await getDictionaries('extraGuaranteesCollectiveContract')
			dispatch(actions.setOptions({ name: 'extraGuaranteesCollectiveContract', data: convertDataToSelectData(result) }))
		} catch (error) {}
	}
}

export const getPositionsByKnz = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('position', { src: '', size: 99999, shortlist: false })
			dispatch(actions.setOptions({ name: 'positionsByKnzFull', data: convertDataToSelectData(content) }))
			dispatch(
				actions.setOptions({
					name: 'positionsByKnz',
					data: convertDataToSelectData(content.filter((e) => e.isLast)),
				})
			)
		} catch (error) {}
	}
}

export const getJobTypes = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('partTime')
			dispatch(actions.setOptions({ name: 'jobTypes', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getWorkingTimeModes = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('workingHours')
			dispatch(actions.setOptions({ name: 'workingTimeModes', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getCountries = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('country', { size: 999 })
			dispatch(actions.setOptions({ name: 'countries', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getRegions = ({ code }) => {
	return async (dispatch) => {
		try {
			if (code.length > 0) {
				const filterContent = await filterKato(code)
				dispatch(actions.setOptions({ name: 'regions', data: convertDataToSelectData(filterContent) }))
			}
		} catch (error) {}
	}
}

export const getRemoteWorkTypes = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('remoteWorkType')
			dispatch(actions.setOptions({ name: 'remoteWorkTypes', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const sendStatuses = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('sendStatus')
			dispatch(actions.setOptions({ name: 'sendStatuses', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const esutdRegErrors = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('esutdRegError', { size: 999 })
			dispatch(actions.setOptions({ name: 'esutdRegErrors', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getRestoreOrganizationTypes = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('restoreOrganizationType')
			dispatch(actions.setOptions({ name: 'restoreOrganizationTypes', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getRestoreOrganizationCourts = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('court', { size: 9999 })
			dispatch(actions.setOptions({ name: 'restoreOrganizationCourts', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getContractTypes = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('contractType')
			dispatch(actions.setOptions({ name: 'contractTypes', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getDistricts = () => {
	return async (dispatch) => {
		try {
			const { content } = await api.handbook.getDistricts()
			dispatch(actions.setOptions({ name: 'districts', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getDistrictsAuth = () => {
	return async (dispatch) => {
		try {
			const filterContent = await filterKato()
			dispatch(actions.setOptions({ name: 'districts', data: convertDataToSelectData(filterContent) }))
		} catch (error) {}
	}
}

export const getPopulatedAreas = (payload) => {
	return async (dispatch) => {
		try {
			const filterContent = await filterKato(payload.code)
			dispatch(actions.setOptions({ name: 'populatedAreas', data: convertDataToSelectData(filterContent) }))
		} catch (error) {}
	}
}

export const getProfessionalSkills = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('specCompetence', { size: 99999 })
			dispatch(actions.setOptions({ name: 'professionalSkills', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getPersonalQualities = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('competence', { size: 99999 })
			dispatch(actions.setOptions({ name: 'personalQualities', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getContractStatuses = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('contractStatus')
			dispatch(actions.setOptions({ name: 'contractStatuses', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getContractStates = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('contractState')
			dispatch(actions.setOptions({ name: 'contractStates', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getOwnerships = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('ownershipType')
			dispatch(actions.setOptions({ name: 'ownerships', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

const dpositionCodeMessages = {
	rus: ' Указанные данные не найдены в справочнике',
	kz: ' Көрсетілген деректер анықтамалықта табылған жоқ',
}

export const findOne = ({ dictCode, saveBy, code }) => {
	return async (dispatch) => {
		try {
			const lang = localStorage.getItem('lang')
			const data = await api.handbook.findOne(dictCode, { code })
			dispatch(
				actions.setSingleOptions({
					name: saveBy,
					data: {
						id: data.id || dpositionCodeMessages[lang],
						code: data.code || dpositionCodeMessages[lang],
						rus: data.nameRu || dpositionCodeMessages[lang],
						kz: data.nameKz || dpositionCodeMessages[lang],
					},
				})
			)
		} catch (error) {}
	}
}

export const getContractTerms = (shortlist = true) => {
	return async (dispatch) => {
		try {
			const { content } = await api.handbook.getContractTerms(shortlist)
			dispatch(actions.setOptions({ name: 'contractTerms', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getColContractTerms = (shortlist = true) => {
	return async (dispatch) => {
		try {
			const content = await api.handbook.getColContractTerms(shortlist)
			dispatch(actions.setOptions({ name: 'colContractTerms', data: content }))
		} catch (error) {}
	}
}

export const getSocialLeaveTypes = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('socialLeaveType')
			dispatch(actions.setOptions({ name: 'socialLeaveTypes', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getLaborType = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('laborType')
			dispatch(actions.setOptions({ name: 'laborType', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getWorkActivityTypes = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('workActivityType')
			dispatch(actions.setOptions({ name: 'workActivityTypes', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getWorkTime = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('workTimeCollectiveContract')
			dispatch(actions.setOptions({ name: 'workTimeCollectiveContract', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getRelaxTime = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('relaxTimeCollectiveContract')
			dispatch(actions.setOptions({ name: 'relaxTimeCollectiveContract', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getWorkActivityGroup = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('workActivityTypeGrouped')
			const transformedContent = { ...content }
			for (let key in transformedContent) {
				transformedContent[key] = convertDataToSelectData(transformedContent[key])
			}
			dispatch(actions.setOptions({ name: 'workActivityTypeGroups', data: transformedContent }))
		} catch (error) {}
	}
}

export const getNonresidentAttractingBasis = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('nonresidentAttractingBasis', { size: 1500 })
			dispatch(actions.setOptions({ name: 'nonresidentAttractingBasis', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getNonresidentEducation = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('nonresidentEducation')
			dispatch(actions.setOptions({ name: 'nonresidentEducation', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getCcEnterpriseType = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('ccEnterpriseType')
			dispatch(actions.setOptions({ name: 'ccEnterpriseType', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getCcOwnershipType = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('ccOwnershipType')
			dispatch(actions.setOptions({ name: 'ccOwnershipType', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getStaffDeptType = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('staffDeptType')
			dispatch(actions.setOptions({ name: 'staffType', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getStaffStatus = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('staffStatus')
			dispatch(actions.setOptions({ name: 'staffStatus', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getStaffEmpStatus = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('staffEmpStatus')
			dispatch(actions.setOptions({ name: 'staffEmpStatus', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getTradeUnionType = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('tradeUnionType')
			dispatch(actions.setOptions({ name: 'unionType', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getOkedsTop = () => {
	return async (dispatch) => {
		try {
			const { content } = await getDictionaries('okedTop', { size: 9999 })
			dispatch(actions.setOptions({ name: 'okedsTop', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getCalcActivityArea = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcActivityArea')
			dispatch(actions.setOptions({ name: 'calcActivityArea', data: content }))
		} catch (error) {}
	}
}

export const getCalcAssessmentCriteria = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcAssessmentCriteria')
			dispatch(actions.setOptions({ name: 'calcAssessmentCriteria', data: content }))
		} catch (error) {}
	}
}

export const getCalcBlockHasCalcDuty = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcBlockHasCalcDuty')
			dispatch(actions.setOptions({ name: 'calcBlockHasCalcDuty', data: content }))
		} catch (error) {}
	}
}

export const getCalcDutyFunction = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcDutyFunction')
			dispatch(actions.setOptions({ name: 'calcDutyFunction', data: content }))
		} catch (error) {}
	}
}

export const getCalcFactor = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcFactor')
			dispatch(actions.setOptions({ name: 'calcFactor', data: content }))
		} catch (error) {}
	}
}

export const getCalcFunctionalBlock = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcFunctionalBlock')
			dispatch(actions.setOptions({ name: 'calcFunctionalBlock', data: content }))
		} catch (error) {}
	}
}

export const getCalcFunctionalDuty = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcFunctionalDuty')
			dispatch(actions.setOptions({ name: 'calcFunctionalDuty', data: content }))
		} catch (error) {}
	}
}

export const getCalcOrganizationType = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcOrganizationType')
			dispatch(actions.setOptions({ name: 'calcOrganizationType', data: content }))
		} catch (error) {}
	}
}

export const getCalcPositionRegister = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcPositionRegister')
			dispatch(actions.setOptions({ name: 'calcPositionRegister', data: content }))
		} catch (error) {}
	}
}

export const getCalcPositionRegisterHasDutyFunction = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcPositionRegisterHasDutyFunction')
			dispatch(actions.setOptions({ name: 'calcPositionRegisterHasDutyFunction', data: content }))
		} catch (error) {}
	}
}

export const getCalcBase = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcBase')
			dispatch(actions.setOptions({ name: 'calcBase', data: content }))
		} catch (error) {}
	}
}

export const getCalcGradeHaveDuty = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcGradeHaveDuty')
			dispatch(actions.setOptions({ name: 'calcGradeHaveDuty', data: content }))
		} catch (error) {}
	}
}

export const getCalcGradeLevel = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('calcGradeLevel')
			dispatch(actions.setOptions({ name: 'calcGradeLevel', data: content }))
		} catch (error) {}
	}
}
export const getAccidentSendStatus = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('accidentSendStatus')
			dispatch(actions.setOptions({ name: 'accidentSendStatus', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getGender = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('gender')
			dispatch(actions.setOptions({ name: 'genders', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getIndustryAgreement = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('industryAgreement')
			dispatch(actions.setOptions({ name: 'industryAgreement', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}


export const getReviewType = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('reviewType')
			dispatch(actions.setOptions({ name: 'reviewType', data: content }))
		} catch (error) {}
	}
}


export const getServiceBinGrantType = () => {
	return async (dispatch, getState) => {
		const { reviewType } = getState().handbook

		try {
			const content = await getDictionaries('serviceBinGrantType')
			dispatch(actions.setOptions({ name: 'serviceBinGrantType', data: content }))
		} catch (error) {}
	}
}
export const getCenterCategory = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('laborMobilityCenterCategory')
			dispatch(actions.setOptions({ name: 'laborMobilityCenterCategory', data: convertDataToSelectData(content) }))
		} catch (error) {}
	}
}

export const getCategoryRelation = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('establishedPostPositionCategoryRelation')
			dispatch(actions.setOptions({ name: 'establishedPostPositionCategoryRelation', data: content }))
		} catch (error) {}
	}
}

export const getIcd = () => {
	return async (dispatch) => {
		try {
			const content = await getDictionaries('icd')
			dispatch(actions.setOptions({ name: 'icd', data: content }))
		} catch (error) {}
	}
}