export const TOGGLE_LOADING = 'accidents/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'accidents/TOGGLE_SUCCESS'
export const TOGGLE_LOADING_SEND = 'accidents/TOGGLE_LOADING_SEND'
export const TOGGLE_SUCCESS_SEND = 'accidents/TOGGLE_SUCCESS_SEND'
export const TOGGLE_ERROR = 'accidents/TOGGLE_ERROR'
export const SET_ACCIDENTS = 'accidents/SET_ACCIDENTS'
export const SET_REPORT_XML = 'accidents/SET_REPORT_XML'
export const SET_ACCIDENT = 'accidents/SET_ACCIDENT'
export const RESET_REPORT_XML = 'accidents/RESET_REPORT_XML'

export const GET_PERSON = 'accidents/GET_PERSON'
export const GET_PERSON_DONE = 'accidents/GET_PERSON_DONE'
export const GET_PERSON_FAIL = 'accidents/GET_PERSON_FAIL'
