import styles from './SelectPerson.module.scss'
import { Button, Field } from '@/ui'
import { Field as FormikField } from 'formik'
import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import { useTranslation } from 'react-i18next'
import { getFLData } from '@/shared/api/rest/contracts'
import { useDispatch, useSelector } from 'react-redux'
import { accidentThunks } from '@/entity'
import { getValueByPath } from '@/utils'
import { Loading } from '@/ui/atoms/loading'
import { LoadingScreen } from '@/components/Loader'

export const SelectPerson = connect(
	({
		formik: { setFieldValue, values, errors },
		wrapperClassName,
		iinFieldName,
		fullNameFieldName,
		iinLabel,
		onSelected = (values) => {},
		onChange = (value) => {}
	}) => {
		const { t } = useTranslation()
		const dispatch = useDispatch()

		const { person } = useSelector((state) => ({
			person: state.accidents.person
		}))

		const handleClick = () => {
			let body = {
				data: {
					iin: getValueByPath(values, iinFieldName)
				},
				onSuccess:(result) => {
					onSelected(result)
				},
				onFailed: () => {

				}
			}
			dispatch(accidentThunks.getPerson(body))
		}

		return (
			<div className={wrapperClassName ?? styles.fields}>
				<FormikField name={iinFieldName}>
					{({ field, meta: { touched, error } }) => (
						<Field
							{...field}
							type="number"
							hideNumberArrows
							isRequired
							label={iinLabel}
							placeholder={t('enter_count_digitals', { count: 12 })}
							error={person.success && touched && error}
							maxLength={12}
							onChange={(value) => {
								field.onChange(value)
								onChange(value)
							}}
						/>
					)}
				</FormikField>
				<Button
					type="button"
					buttonClassName={styles.selectButton}
					onClick={handleClick}
					disabled={person.loading}
				>
					{t('find')}
				</Button>

				<FormikField name={fullNameFieldName}>
					{({ field, meta: { touched, error } }) => (
						<Field
							{...field}
							classes={person.loading ? styles.progressbar : ''}
							type="text"
							isRequired
							disabled
							label={t('employee_initials')}
							error={touched && error}
							onChange
						/>
					)}
				</FormikField>
			</div>
		)
	}
)
