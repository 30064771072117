import { getFromLocalStorage, setToLocalStorage, api } from '@/shared'
import { userActions } from '@/entity/user'
import { actions } from './actions'
import { companyActions } from '@/entity/company'
import { employerProfileThunks } from '@features/employerProfile/model'

export const registration = ({ data: { bin, password, email, phone }, type, history }) => {
	return async (dispatch) => {
		try {
			const data = {
				iinbin: bin,
				password: password,
				email,
				phone,
				isFl: type !== 'employer',
			}
			await api.auth.registration(data)

			dispatch(authorizationByCredentials({ data: { bin, password }, history }))
			dispatch(actions.setError(''))
		} catch (error) {
			const message = error.response.data.message
			if (message === 'Пользователь с таким email уже существует') dispatch(actions.setError('already_exist_email'))
			if (message === 'Пользователь с таким БИН уже существует') dispatch(actions.setError('already_exist_bin'))
			if (message === 'Пользователь с таким ИИН уже существует') dispatch(actions.setError('already_exist_iin'))
			if (message === 'Юр.лицо не найдено') dispatch(actions.setError('employer_doesnt_found'))
			if (message === 'Физ.лицо не найдено в БД ГБДФЛ') dispatch(actions.setError('worker_doesnt_found'))
		}
	}
}

export const authorizationByCredentials = ({ data: { bin, password }, history }) => {
	return async (dispatch) => {
		try {
			const data = {
				iinbin: bin,
				password: password,
			}
			const { token, userType } = await api.auth.authorisation(data)
			setToLocalStorage('token', token)
			setToLocalStorage('type', userType)
			dispatch(actions.setError(''))
			if (userType === 'EMPLOYER') {
				setToLocalStorage('role', 'ROLE_EMPLOYER')
				history.push('/employer-profile')
				return
			}
			setToLocalStorage('role', 'ROLE_EMPLOYEE')
			history.push('/worker-profile')
		} catch (error) {
			const message = error.response.data.errorType
			if (message === 'BAD_CREDENTIALS') {
				dispatch(actions.setError('bad_credentials'))
			}
		}
	}
}

export const sendCodeToEmail = (payload) => {
	return async (dispatch) => {
		try {
			await api.auth.resetPassword.sendCodeToEmail(payload)
			dispatch(actions.setSuccessSentCode(true))
			dispatch(actions.setResetPasswordError(''))
		} catch (error) {
			const response = error.response.data.message
			if (response === 'Пользователь не найден') dispatch(actions.setResetPasswordError(response))
		}
	}
}

export const getCurrentUser = (payload, params = {}, onSuccess = () => {}) => {
	//TODO ID и CODE по умолчанию, используется в случае если company.id равен null для ФЛ
	const DEFAULT_ID = 123456789

	return async (dispatch) => {
		try {
			dispatch(actions.toggleLoading(true))
			const { data: sendData } = payload
			const user = await api.auth.getCurrentUser(sendData, params)
			const { currentBin, companies, currentFlEmployer } = user || {}
			const activeRoleFroLS = getFromLocalStorage('activeRole')
			const activeCompany = getFromLocalStorage('activeCompany')
			const initialCompany =
				activeCompany ||
				(currentBin &&
					companies.find(({ bin, flEmployer }) => bin === currentBin && flEmployer === currentFlEmployer)) ||
				companies[0]
			const parsedCompanies = companies.map((company) => ({
				id: company.id !== null ? company.id : DEFAULT_ID,
				rus: company.companyName,
				kz: company.companyName,
				code: String(company.id !== null ? company.id : DEFAULT_ID),
			}))

			setToLocalStorage('roles', user?.roles || [])
			setToLocalStorage('isLogged', true)
			setToLocalStorage('activeStaffBranch', user.currentStaffBranch)

			onSuccess()

			dispatch(
				userActions.setUser({
					roles: user?.roles || [],
					currentFlEmployer: user?.currentFlEmployer,
					...user?.personDetails,
					personDetailsId: user?.personDetails?.id,
					email: user?.email,
					id: user?.id,
					manager: user?.manager,
					errorCompanies: user?.errorCompanies,
				})
			)
			dispatch(userActions.setActiveRole(activeRoleFroLS))
			dispatch(userActions.setEmployerCompanies(parsedCompanies))
			dispatch(
				companyActions.setCompanies(
					companies.map((company) => ({
						...company,
						id: company.id !== null ? company.id : DEFAULT_ID,
						code: String(company.id !== null ? company.id : DEFAULT_ID),
					}))
				)
			)
			if (initialCompany?.bin)
				dispatch(employerProfileThunks.getCompany({ id: currentFlEmployer ? DEFAULT_ID : initialCompany?.id || null }))
		} catch (error) {
			dispatch(actions.setError(error))
			throw error
		} finally {
			dispatch(actions.toggleLoading(false))
		}
	}
}
