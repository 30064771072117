import { object, string } from 'yup'
import * as yup from 'yup'
import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'

export const industryAgreementSchema = (type) => {
	return object().shape({
		industryAgreementNameKz: string().required({ key: 'required' }),
		industryAgreementNameRu: string().required({ key: 'required' }),
		authStateNameKz: string().required({ key: 'required' }),
		authStateNameRu: string().required({ key: 'required' }),
		createDate: transformStringDateFormatToDate().required({ key: 'required' }),
		dateFrom: transformStringDateFormatToDate().required({ key: 'required' }),
		dateTo: transformStringDateFormatToDate().when('dateFrom', (dateFrom) => {
			if (dateFrom)
				return transformStringDateFormatToDate()
					.min(dateFrom, { key: 'date.end_date_before_start' })
					.nullable()
					.required({ key: 'required' })
		}),
		industryCodes: yup.array().min(1, 'required'),
		tradeUnionNames: yup.array().of(
			yup.object().shape({
				tradeUnionNameKz: string().required({ key: 'required' }),
				tradeUnionNameRu: string().required({ key: 'required' }),
			})
		),
		employerAssociationNames: yup.array().nullable().of(
			yup
				.object()
				.shape({
					employerAssociationNameKz: yup.string().nullable(),
					employerAssociationNameRu: yup.string().nullable(),
				})
				.test('item', 'Это поле является обязательным', (item) => {
					const isKzEmpty = !item.employerAssociationNameKz
					const isRuEmpty = !item.employerAssociationNameRu

					if (isKzEmpty || isRuEmpty) {
						return isKzEmpty && isRuEmpty
					}

					return true
				})
		),
		extraGuarantee: yup.array().of(
			yup.object().shape({
				code: string().required({ key: 'required' }),
				valueKz: string().required('required'),
				valueRu: string().required('required'),
			})
		),
		...(type == 'add' ? {
			updatesList: yup.array().of(
				yup.object().shape({
					updateDateTime: transformStringDateFormatToDate().when('externalId', {
						is: (externalId) => !!externalId,
						then: transformStringDateFormatToDate().required({ key: 'required' }),
						otherwise: transformStringDateFormatToDate().nullable(),
					}),
					updateTextKz: yup.string().when('externalId', {
						is: (externalId) => !!externalId,
						then: yup.string().required('required'),
						otherwise: yup.string(),
					}),
					updateTextRu: yup.string().when('externalId', {
						is: (externalId) => !!externalId,
						then: yup.string().required('required'),
						otherwise: yup.string(),
					}),
				})
			)
		} : {})
	})
}
