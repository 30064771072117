import cl from 'classnames'
import styles from './Style.module.scss'
import React, { useMemo } from 'react'
import foto4 from '@/assets/home/foto4.png'
import foto5 from '@/assets/home/foto5.png'
import foto2 from '@/assets/home/foto2.png'
import foto8 from '@/assets/home/foto8.png'
import foto3 from '@/assets/home/foto3.png'
import foto1 from '@/assets/home/foto1.png'
import useEnbekEcoSystemLink from '@/shared/lib/hooks/useEnbekEcoSystemLink'
import { useTranslation } from 'react-i18next'
import { LANGUAGE_ENUM, useLanguage } from '@/shared'
import { useSelector } from 'react-redux'
import { StyledSlider } from '@features/home/Styled'

const settings = {
	infinite: true,
	swipeToSlide: true,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	initialSlide: 0,
	responsive: [
		{
			breakpoint: 1450,
			settings: {
				slidesToShow: 4,
			},
		},
		{
			breakpoint: 1300,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
			}
		}
	]
}

const Info = () => {
	const { t } = useTranslation()
	const { lang } = useLanguage()

	const { user, activeRole, companies } = useSelector((state) => ({
		user: state.user.user,
		activeRole: state.user.activeRole,
		companies: state.user.employerCompanies,
	}))

	const condition = useMemo(() => {
		let res = (activeRole === 'ROLE_EMPLOYER' && !companies.length) || (activeRole === 'ROLE_EMPLOYEE' && !user?.iin)
		return res ? '/reference-information' : '/contracts'
	}, [activeRole, companies.length, user])

	const currentLangPrefix = {
		[LANGUAGE_ENUM.rus]: 'ru',
		[LANGUAGE_ENUM.kz]: 'kz',
	}

	const urls = useMemo(() => {
		return {
			auth: process.env.REACT_APP_LOGIN + currentLangPrefix[lang] + process.env.REACT_APP_REDIRECT_ROLE,
			vac: process.env.REACT_APP_REDIRECT_ENBEK + currentLangPrefix[lang] + process.env.REACT_APP_VAC,
			res: process.env.REACT_APP_REDIRECT_ENBEK + currentLangPrefix[lang] + process.env.REACT_APP_RES,
		}
	}, [lang])

	const array = useMemo(
		() => [
			{
				title: t('register_employment_contracts_online'),
				ref: !user ? urls.auth : condition,
				link: foto4,
			},
			{
				title: t('learn_about_integration_unified_accounting_system_employment_contracts'),
				ref: '/reference-information',
				link: foto5,
			},
			{
				title: t('pass_self_assessment_compliance_labor_law_requirements'),
				ref: '/forms',
				link: foto2,
			},
			{
				title: t('frequently_asked_questions'),
				ref: '/reference-information',
				link: foto8,
			},
			{
				title: t('find_job_title'),
				ref: urls.vac,
				link: foto3,
			},
			{
				title: t('find_employee'),
				ref: urls.res,
				link: foto1,
			},
		],
		[condition, urls]
	)

	return (
		<section data-section='info' className={cl(styles.info)}>
			<div className={cl(styles.info__wrapper)}>
				<StyledSlider {...settings}>
					{array.map((arr, index) => {
						return (
							<div key={index} className={cl(styles.info__slider)}>
								<img src={arr.link} alt={arr.title} />
								<span>{arr.title}</span>
								<a href={arr.ref}>
									{t('more_details')}
								</a>
							</div>
						)
					})}
				</StyledSlider>
			</div>
		</section>
	)
}

export default Info