import styles from './Step.module.scss'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const Step = ({ index, label, opened, disabled, children }) => {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(opened)

	const toggleMenu = () => {
		!disabled && setIsOpen(!isOpen)
	}

	useEffect(() => {
		setIsOpen(opened)
	}, [opened])

	return (
		<div className={styles.caption}>
			<div className={styles.caption_item} onClick={toggleMenu}>
				<div className={styles.caption_item_title}>
					<span
						className={classNames(
							styles.caption_item_title_circle,
							disabled ? styles.caption_item_title_circle_disabled : styles.caption_item_title_circle_active
						)}
					>
						{index}
					</span>
					<label>{t(label)}</label>
				</div>

				{!disabled && (
					<svg
						className={classNames(styles.caption_item_arrow, isOpen ? '' : styles.caption_item_arrow__opened)}
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.0004 12.8L10.1691 6.96875L4.33789 12.8"
							stroke="#5B6880"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				)}
			</div>
			<div className={classNames(styles.content, isOpen ? styles.content_show : styles.content_hide)}>
				{children}
			</div>
		</div>
	)
}
