import { Logo as LogoImage } from '@/app/images'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@/components/Icon'
import { EcoSystemEnbek } from '@app/icons/ecoSystemEnbek'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import cl from 'classnames'
import styles from './Style.module.scss'
import { LANGUAGE_ENUM, useClickOutside, useLanguage } from '@/shared'
import useEnbekEcoSystemLink from '@/shared/lib/hooks/useEnbekEcoSystemLink'
import enbekLogo from '@/assets/home/enbek-eco-logo.svg'
import skillsLogo from '@/assets/home/skills-eco-logo.svg'
import business from '@/assets/home/businnes-eco-logo.svg'
import career from '@/assets/home/career-eco-logo.svg'
import migration from '@/assets/home/enbek-migration-logo.svg'
import mansap from '@/assets/home/enbek-mansap-logo.svg'
import { useSelector } from 'react-redux'

const Header = () => {
	const { t } = useTranslation()
	const ecosystemRef = useRef(null)

	const { user } = useSelector((state) => ({
		user: state.user.user,
	}))

	const [isEcosystemVisible, setIsEcosystemVisible] = useState(false)

	const { lang, handleChangeLanguage } = useLanguage()

	const { refEnbek, refSkills, refBusiness, refCareer, refMigration, refMansap } = useEnbekEcoSystemLink(lang)

	const menuArray = [
		{
			link: '/about-project',
			label: t('about_project'),
		},
		{
			link: '/for-employers',
			label: t('for_employer'),
		},
		{
			link: '/for-workers',
			label: t('for_worker'),
		},
		{
			link: '/reference-information',
			label: t('reference_information'),
		},
	]

	const ecosystemArray = [
		{
			title: 'Enbek',
			description: t('actual_resumes_vacancies'),
			icon: enbekLogo,
			link: refEnbek,
		},
		{
			title: 'Enbek Skills',
			description: t('learning_skills'),
			icon: skillsLogo,
			link: refSkills,
		},
		{
			title: 'Enbek Business',
			description: t('support_business'),
			icon: business,
			link: refBusiness,
		},
		{
			title: 'Enbek Career',
			description: t('career_road'),
			icon: career,
			link: refCareer,
		},
		{
			title: 'Enbek Migration',
			description: t('migration_description'),
			icon: migration,
			link: refMigration,
		},
		{
			title: 'Enbek Mansap Kompasy',
			description: t('mansap_description'),
			icon: mansap,
			link: refMansap,
		},
	]

	const handleLangClick = (e) => {
		e.preventDefault()

		const currentLanguage = {
			[LANGUAGE_ENUM.kz]: LANGUAGE_ENUM.rus,
			[LANGUAGE_ENUM.rus]: LANGUAGE_ENUM.kz,
		}

		handleChangeLanguage(currentLanguage[lang])
	}

	const langLabel = {
		[LANGUAGE_ENUM.kz]: 'KZ',
		[LANGUAGE_ENUM.rus]: 'RU',
	}

	useClickOutside(ecosystemRef, isEcosystemVisible, () => setIsEcosystemVisible(false))

	let urlAuthByLang = {
		[LANGUAGE_ENUM.kz]: process.env.REACT_APP_LOGIN + 'ru' + process.env.REACT_APP_REDIRECT_ROLE,
		[LANGUAGE_ENUM.rus]: process.env.REACT_APP_LOGIN + 'kk' + process.env.REACT_APP_REDIRECT_ROLE,
	}

	return (
		<header className={cl(styles.container, styles.header)}>
			<Link to={'/'} className={cl(styles.header__logo)}>
				<img src={LogoImage} alt="enbek" width={146} height={52} />
			</Link>

			<div className={cl(styles.header__ecosystem)}  ref={ecosystemRef}>
				<Icon component={EcoSystemEnbek} className={cl(styles.header__ecosystemIcon)} onClick={() => setIsEcosystemVisible(prevState => !prevState)}/>

				{
					isEcosystemVisible &&
					<ul className={cl(styles.header__ecosystemArray)}>
						{
							ecosystemArray.map(item => (
								<li key={item.link}>
									<a href={item.link}>
										<img src={item.icon} alt={item.title} width={34} height={34}/>

										<div>
											<h5 className={cl('mb-0')}>
												{item.title}
											</h5>
											<p className={cl('mb-0')}>
												{item.description}
											</p>
										</div>
									</a>
								</li>
							))
						}
					</ul>
				}
			</div>

			<nav className={cl(styles.header__nav)}>
				<ul>
					{menuArray.map(item => (
						<li key={item.link}>
							<Link to={item.link}>
								{item.label}
							</Link>
						</li>
					))}
				</ul>
			</nav>

			<a onClick={handleLangClick} className={styles.header__lang}>
				{langLabel[lang]}
			</a>

			{
				!user ? (
					<a href={urlAuthByLang[lang]}>
						<Button>
							{t('entrance')}/{t('registration')}
						</Button>
					</a>
				) : (
					<Link to={'/role'}>
						<Button>
							{t('entrance')}
						</Button>
					</Link>
				)
			}

		</header>
	)
}

export default Header