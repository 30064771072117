import React, { useState, useMemo, useEffect } from 'react'
import { DialogTitle, DialogContent, Dialog, IconButton, Button, Box, Grid } from '@mui/material'
import styles from '../TerminateElectronic.module.scss'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { Form, Formik, Field as FormikField, connect } from 'formik'
import { DatePicker } from '@/components/DatePicker'
import { Add, Trash } from '@app/icons'
import { contractTerminationSchema } from '../contractTermination.schema'
import { SelectSearch, Button as AppButton, Checkbox } from '@/ui'
import { useSelector, useDispatch } from 'react-redux'
import { userSelectors } from '@/entity'
import { contractsThunks, contractsSelectors } from '@/entity/contracts'
import { Button as DialogButton, Field as AppField } from '@/ui'
import { request } from '@/shared/api/request'
import CloseButton from '@/components/CloseButton/CloseButton'
import { message } from 'antd'

export const Decline = ({ onClose, onUpdate, selectedContract }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const activeRole = useSelector(userSelectors.activeRole)

	const onSubmit = async (e) => {
		e.preventDefault()
		const role = activeRole == 'ROLE_EMPLOYER' ? 'employer' : 'employee'

		try {
			// setTemplatesLoading(true)
			const res = await request({
				url: `/contract/eContractTerm/${role}/reject`,
				method: 'POST',
				params: {
					termExternalId: selectedContract.termExternalId,
					note: declineText,
				},
			})
			onUpdate()
			onClose()
			message.success(t('success_alert'))
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		}
	}
	const [declineText, setDeclineText] = useState('')

	return (
		<Dialog scroll={'paper'} classes={{ paper: styles.modal__dialog }} onClose={onClose} open={true}>
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="flex-end">
					{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box className={styles.modal__text}>{t('decline_etd')}</Box>

				<form onSubmit={onSubmit}>
					<AppField
						wrapperClassName={styles.textarea__wrapper}
						placeholder={t('el_cause_short')}
						rows={4}
						fieldType="textarea"
						name="decline_text"
						value={declineText}
						onChange={(e) => setDeclineText(e)}
					/>

					<Box display="flex" alignItems="center" justifyContent="center">
						<AppButton type="submit">{t('actions.revoke')}</AppButton>
					</Box>
					<Box display="flex" alignItems="center" justifyContent="center">
						<AppButton transparent onClick={onClose}>
							{t('cancel')}
						</AppButton>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	)
}
