import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import React, { useCallback, useEffect, useState } from 'react'
import { agreementsThunks, agreementsSelectors, agreementsActions } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { HrPagination } from '@/components/Pagination'
import { initialQueryState } from '@/entity/industryAgreement/model/reducer'
import { LoadingBlocker } from '@/components/Loader'
import { CreateOrEdit } from '@features/industryAgreement/createOrEdit/CreateOrEdit'
import AgreementTable from '@features/industryAgreement/table/AgreementTable'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { View } from '@features/industryAgreement/view/View'
import { Button } from '@/ui'
import { Book } from '@app/images'
import { useToggle } from '@/shared'
import { useTranslation } from 'react-i18next'

export const IndustryAgreement = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const isLoading = useSelector(agreementsSelectors.isLoading)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const { industryAgreement } = activeCompany
	const agreements = useSelector(agreementsSelectors.agreements)
	const agreement = useSelector(agreementsSelectors.agreement)

	const [isView, openView] = useState(false)
	const [editModalData, setEditModalData] = useState({
		isVisible: false,
		type: null
	})
	const [isCreate, toggleCreate] = useToggle(false)

	useEffect(() => {
		dispatch(agreementsThunks.getDict())
	}, [])

	const fetchData = useCallback(
		(_queryParams = queryParams) => {
			const { page, pageSize } = _queryParams
			dispatch(agreementsThunks.getPage(page, pageSize))
		},
		[queryParams]
	)

	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize])

	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	const Close = () => {
		openView(false)
		toggleCreate(false)
		dispatch(agreementsActions.resetAgreementXml())
		dispatch(agreementsActions.setAgreementSuccess(false))
	}
	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={'industryAgreement.title'}>
				{isLoading && <LoadingBlocker />}
				{industryAgreement ? (
					<Button icon={Book} iconSize={'big'} onClick={() => {
						toggleCreate()
						setEditModalData({isVisible: false, type: 'create'})
					}}>
						{t('industryAgreement.create')}
					</Button>
				) : null}
				{isCreate ? (
					<CreateOrEdit
						industryAgreement={industryAgreement}
						editModalData={editModalData}
						fetchData={fetchData}
						onClose={Close}
					></CreateOrEdit>
				) : null}
				{agreements && agreements.totalElements > 0 ? (
					<>
						<AgreementTable data={agreements.industryAgreements} view={openView}></AgreementTable>
						<HrPagination
							id="hr-pagination"
							rowsPerPageitems={queryParams.rowsPerPage}
							rowsPerPage={queryParams.pageSize}
							numberOfElements={agreements.numberOfElements}
							totalElements={agreements.totalElements}
							totalPages={agreements.totalPages}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							page={queryParams.page}
						/>
					</>
				) : (
					<InfoDevelopment title={'industryAgreement.info'} />
				)}
				{isView && !!agreement ? (
					<View
						agreement={agreement}
						onClose={Close}
						industryAgreement={industryAgreement}
						fetchData={fetchData}
						editModalData={editModalData}
						setEditModalData={setEditModalData}
					></View>
				) : null}
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
