import { accidentActions } from '@/entity'
import { api } from '@/shared'
import { message } from 'antd'
import { getFLData } from '@/shared/api/rest/contracts'
import i18next from 'i18next'

export const getAccidents = (page, size) => {
	return async (dispatch) => {
		try {
			dispatch(accidentActions.toggleError(null))
			dispatch(accidentActions.toggleLoading(true))
			const result = await api.accidents.getAll({ page, size })
			dispatch(accidentActions.setAccidents(result))
			dispatch(accidentActions.toggleSuccess(true))
			dispatch(accidentActions.toggleLoading(false))
		} catch (error) {
			dispatch(accidentActions.toggleError(error))
			dispatch(accidentActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(accidentActions.toggleLoading(false))
		}
	}
}

export const saveReport = ({ data, onSuccess }) => {
	return async (dispatch) => {
		try {
			dispatch(accidentActions.toggleLoading(true))
			const result = await api.accidents.saveReport(data)

			onSuccess(result)
			dispatch(accidentActions.toggleLoading(false))
		} catch (error) {
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(accidentActions.toggleLoading(false))
		}
	}
}

export const sendSignedReport = ({ data, onSuccess }) => {
	return async (dispatch) => {
		try {
			dispatch(accidentActions.toggleLoadingSend(true))
			const result = await api.accidents.sendReport(data)
			if (result === '') {
				dispatch(accidentActions.toggleSuccessSend(true))
			}
			onSuccess()
		} catch (error) {
			message.error(error?.response?.data?.message)
			dispatch(accidentActions.resetReportXml())
			dispatch(accidentActions.toggleLoadingSend(false))
		} finally {
			dispatch(accidentActions.toggleLoadingSend(false))
		}
	}
}

export const getAccident = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(accidentActions.setAccident(null))
			dispatch(accidentActions.toggleLoading(true))
			const result = await api.accidents.getAccident(externalId)
			dispatch(accidentActions.setAccident(result))
			dispatch(accidentActions.toggleLoading(false))
		} catch (error) {
			dispatch(accidentActions.toggleSuccess(false))
			dispatch(accidentActions.setAccident(null))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(accidentActions.toggleLoading(false))
		}
	}
}


export const getPerson = ({data, onSuccess, onFailed}) => {
	return async (dispatch) => {
		try {
			dispatch(accidentActions.getPerson())

			const getPersonByContract = await api.staff.getContractData(data)
			let res

			if(getPersonByContract?.contractDatas?.length){
				res = {
					...getPersonByContract,
					fio: getPersonByContract?.fio,
					surname: getPersonByContract?.surname,
					firstname: getPersonByContract?.firstname,
					secondname: getPersonByContract?.patronname,
					contractDatas: getPersonByContract?.contractDatas?.map((i, idx) => ({...i, establishedPost: {...i.establishedPost, key: `${idx}-${i.establishedPost?.code}`, kz: i.establishedPost?.nameKz, rus: i.establishedPost?.nameRu}}))
				}
				dispatch(accidentActions.getPersonDone(res))
			} else {
				const getByFlData = await api.contracts.getFLData(data)

				if(getByFlData.fio !== null){
					res = {
						...getByFlData,
						iin: data?.iin,
						fio: getByFlData?.fullNamePerson,
						surname: getByFlData?.surname,
						firstname: getByFlData?.firstname,
						secondname: getByFlData?.patronname,
					}
					dispatch(accidentActions.getPersonDone(res))
				} else {
					onFailed()
					dispatch(accidentActions.getPersonFail())
					message.error(i18next.t('person_not_found'), 10)
				}
			}

			onSuccess(res)
		} catch (error) {
			dispatch(accidentActions.getPersonFail())
			onFailed()
			message.error(error?.response?.data?.message)
		}
	}
}
