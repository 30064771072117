import { ConfigProvider, DatePicker } from 'antd'
import styles from './style.module.scss'
import React, { useEffect, useState } from 'react'
import ru from 'antd/es/locale/ru_RU'
import kz from 'antd/es/locale/kk_KZ'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/kk'
import { getCurrentLanguage } from '@/i18next'

const locales = {
	rus: {
		moment: 'ru',
		configProvider: ru,
	},
	kz: {
		moment: 'kk',
		configProvider: kz,
	},
}

export const DateTimePicker = ({ onChange = () => {}, ...props }) => {
	const lang = getCurrentLanguage()
	const [currentLocale, setCurrentLocale] = useState(locales[lang])

	useEffect(() => {
		setCurrentLocale(locales[lang])
		moment.locale(locales[lang].moment)
	}, [])

	return (
		<ConfigProvider locale={currentLocale.configProvider}>
			<DatePicker
				showTime
				size="large"
				className={styles.createDateRangePicker}
				format={'DD.MM.YYYY HH:mm'}
				onChange={(value, dateString) => {
					onChange(value, dateString)
				}}
				{...props}
			/>
		</ConfigProvider>
	)
}
