import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from './Form'

import { accidentActions, AccidentReportValidationSchema, accidentSelectors, accidentThunks } from '@/entity'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'
import { useDigitalSign } from '@/shared'
import { DigitalSign } from '@/utils/digitalSign'
import { message } from 'antd'

export const AccidentReport = ({ onClose }) => {
	const dispatch = useDispatch()
	const districts = useSelector(handbookSelectors.districts)
	const positions = useSelector(handbookSelectors.positions)
	const positionsNkz = useSelector(handbookSelectors.positionsByKnz)
	const genders = useSelector(handbookSelectors.genders)

	const digitalSign = new DigitalSign()

	useEffect(() => {
		if (!districts?.length > 0) dispatch(handbookThunks.getDistrictsAuth())
		if (!positions?.length > 0) dispatch(handbookThunks.getPositions())
		if (!positionsNkz?.length > 0) dispatch(handbookThunks.getPositionsByKnz())
		if (!genders?.length > 0) dispatch(handbookThunks.getGender())
	}, [])

	const onSubmit = (data) => {
		const updatedInjureds = data.injureds.map((injured) => {
			const { fio, isContract, isValid, ...injuredWithoutFio } = injured
			if (injured.isResident === true) {
				const { birthDate, passportNumber, issuredBy, issuredWhen, ...residentFields } = injuredWithoutFio
				return residentFields
			} else if (injured.isResident === false) {
				const { iin, ...nonResidentFields } = injuredWithoutFio
				return nonResidentFields
			}
			return injuredWithoutFio
		})

		const {isContract, isValid, ...submittedData} = data

		const updatedData = {
			...submittedData,
			injureds: updatedInjureds,
		}

		const body = {
			data: updatedData,
			onSuccess: async ({ externalId, xmlForSigned }) => {
				const res = await digitalSign.handleSend(xmlForSigned)

				let signedBody = {
					data: { externalId, signedXml: res },
					onSuccess: () => {
						message.success('Успешно!')
						onClose()
					},
				}
				dispatch(accidentThunks.sendSignedReport(signedBody))
			}
		}

		dispatch(accidentThunks.saveReport(body))
	}

	return (
		<Formik
			initialValues={{ injureds: [{ isResident: true }] }}
			validationSchema={AccidentReportValidationSchema}
			onSubmit={onSubmit}
		>
			<Form onClose={onClose} />
		</Formik>
	)
}
