export const headCells = [
	{
		id: 'number',
		numeric: true,
		label: '№',
		width: 50
	},
	{
		id: 'bin',
		numeric: true,
		label: 'industryAgreement.industryAgreement',
		width: 400
	},
	{
		id: 'name',
		numeric: false,
		label: 'industryAgreement.industryAgreementName',
	},
	{
		id: 'date_from',
		numeric: false,
		label: 'col_contract.table.date_from',
		width: 150
	},
	{
		id: 'date_to',
		numeric: false,
		label: 'col_contract.table.date_to',
		width: 150
	},
]
