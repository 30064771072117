import * as yup from 'yup'
import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'
import { string } from 'yup'

export const contractTerminationSchema = (isValid) => {
	return yup.object().shape({
		terminationDate: transformStringDateFormatToDate()
			.required({ key: 'required' })
			.max(new Date(), { key: 'date.can_not_be_after_now' }),
		terminationReason: yup.string().required(),

		...(isValid ? {
			employeeAccountNumber: string()
				.nullable()
				.when('employeeRequest', {
					is: true,
					then: yup.string().min(20, { key: 'min_length', value: 20 }).required({ key: 'required' }),
				}),
			hrManagerPhone: yup
				.string()
				.nullable()
				.when('employeeRequest', { is: true, then: yup.string().required({ key: 'required' }) }),
			employeePhone: yup
				.string()
				.nullable()
				.when('employeeRequest', { is: true, then: yup.string().required({ key: 'required' }) }),
		} : {}),
	})
}
