import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BlackLogo } from '@app/images'
import LangFooter from '@/components/LangFooter'
import HeaderButton from '@/components/HeaderButton/HeaderButton'
import { getCurrentLanguage } from '@/i18next'

const Header = ({ dark, active }) => {
	const lang = getCurrentLanguage()
	const { t } = useTranslation()

	const links = [
		{ label: 'about_project', link: '/about-project' },
		{ label: 'for_employer', link: '/for-employers' },
		{ label: 'for_worker', link: '/for-workers' },
		{ label: 'reference_information', link: '/reference-information' },
	]

	return (
		<div className="container">
			<div className={`header-wrapper ${dark ? 'header-wrapper--dark' : ''}`}>
				<Link to={'/'}>
					<img className="logo" src={BlackLogo} />
				</Link>
				<ul className="menu">
					{links.map(({ link, label }) => (
						<li key={label}>
							<Link to={link} className={active === label ? 'header-li-active' : 'header-li'}>
								{' '}
								{t(label)}
							</Link>
						</li>
					))}
					<LangFooter header dark />
				</ul>
				<HeaderButton lang={lang} />
			</div>
		</div>
	)
}

export default Header
