import * as Yup from 'yup'
import { transformStringDateFormatToDate } from '@/shared'
import { countriesIds } from '@app/config'
import { string } from 'yup'

const citiesInRegionsCodes = ['750000000', '710000000', '790000000']

Yup.addMethod(Yup.string, 'checkCountryRemoteWork', function () {
	return this.when(['dremoteWorkCode', 'dcountryCode'], {
		is: (remoteWork, country) => remoteWork !== '1' && +country === countriesIds.kz,
		then: (schema) => schema.required({ key: 'required' }),
	})
})

export const ValidationSchema = (conditions) =>
	Yup.object().shape({
		contractNum: Yup.string().required({ key: 'required' }).nullable(),
		registerDate: transformStringDateFormatToDate().required({ key: 'required' }).nullable(),
		dateFrom: transformStringDateFormatToDate().required({ key: 'required' }).nullable(),
		dateTo: transformStringDateFormatToDate().nullable(),
		dcontractDateCode: conditions?.contractTerm
			? Yup.string().required({ key: 'required' }).nullable()
			: Yup.string().nullable(),
		contractEndDate: conditions?.contractTerm
			? transformStringDateFormatToDate()
					.nullable()
					.when('dcontractDateCode', {
						is: (dcontractDateCode) =>
							dcontractDateCode !== '1' &&
							dcontractDateCode !== '4' &&
							dcontractDateCode !== '5' &&
							dcontractDateCode !== '7' &&
							dcontractDateCode !== '8',
						then: transformStringDateFormatToDate().required({ key: 'required' }).nullable(),
					})
			: transformStringDateFormatToDate().nullable(),
		dworkingHoursCode: conditions?.contractWorkingHours
			? Yup.string().required({ key: 'required' }).nullable()
			: Yup.string().nullable(),
		tariffRate: conditions?.contractWorkingHours
			? Yup.string()
					.nullable()
					.when('dworkingHoursCode', {
						is: (dworkingHoursCode) => dworkingHoursCode === '3',
						then: Yup.string().required({ key: 'required' }).nullable(),
					})
			: Yup.string().nullable(),
		dpositionCode: conditions?.contractPosition
			? Yup.string().required({ key: 'required' }).nullable()
			: Yup.string().nullable(),
		dworkingConditionsCode:
			conditions?.contractPosition && !conditions?.econtractFound
				? Yup.string().required({ key: 'required' }).nullable()
				: Yup.string().nullable(),
		establishedPost: conditions?.contractPosition
			? Yup.string().required({ key: 'required' }).nullable()
			: Yup.string().nullable(),
		dpartTimeCode: conditions?.contractJobType
			? Yup.string().required({ key: 'required' }).nullable()
			: Yup.string().nullable(),
		dremoteWorkCode: conditions?.contractJobLocation
			? Yup.string().required({ key: 'required' }).nullable()
			: Yup.string().nullable(),
		dcountryCode: conditions?.contractJobLocation
			? Yup.string()
					.nullable()
					.when('dremoteWorkCode', {
						is: '1',
						otherwise: (schema) => schema.required({ key: 'required' }),
					})
			: Yup.string().nullable(),
		ddistrictCode: conditions?.contractJobLocation
			? Yup.string().nullable().checkCountryRemoteWork()
			: Yup.string().nullable(),
		dregionCode: conditions?.contractJobLocation
			? Yup.string().nullable().checkCountryRemoteWork()
			: Yup.string().nullable(),
		dlocalityCode: conditions?.contractJobLocation
			? Yup.string()
					.nullable()
					.when(['dremoteWorkCode', 'ddistrictCode', 'dcountryCode'], {
						is: (remoteWork, district, country) =>
							(remoteWork && remoteWork === '1') ||
							(district && citiesInRegionsCodes.includes(district)) ||
							(country && Number(country) !== countriesIds.kz),
						otherwise: (schema) => schema.nullable().required({ key: 'required' }),
					})
			: Yup.string().nullable(),
		workingPlace: conditions?.contractJobLocation
			? Yup.string()
					.nullable()
					.when(['dremoteWorkCode', 'dcountryCode'], {
						is: (remoteWork, country) => remoteWork && remoteWork !== '1' && country,
						then: (schema) => schema.required({ key: 'required' }),
					})
			: Yup.string().nullable(),
		otherConditions: conditions?.contractOther
			? Yup.string().required({ key: 'required' }).nullable()
			: Yup.string().nullable(),
		...(conditions.isValid ? {
			employeeAccountNumber: string()
				.nullable()
				.when('employeeRequest', {
					is: true,
					then: Yup.string().min(20, { key: 'min_length', value: 20 }).required({ key: 'required' }),
				}),
			hrManagerPhone: Yup.string()
				.nullable()
				.when('employeeRequest', { is: true, then: Yup.string().required({ key: 'required' }) }),
			employeePhone: Yup.string()
				.nullable()
				.when('employeeRequest', { is: true, then: Yup.string().required({ key: 'required' }) }),
		} : {}),
	})
