import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { Button, ModalTemplate, Title } from '@/ui'
import styles from './View.module.scss'
import React, { useState } from 'react'
import { GeneralData } from '@features/industryAgreement/info-table/GeneralData'
import { CoefData } from '@features/industryAgreement/info-table/CoefData'
import { GuaranteeData } from '@features/industryAgreement/info-table/GuaranteeData'
import { InformationSection, TableHistory } from '@features/industryAgreement/view/components'
import { CreateOrEdit } from '@features/industryAgreement'
import cl from 'classnames'
import { Edit } from '@app/icons'
import { Icon } from '@/components/Icon'

export const View = ({ agreement, industryAgreement, editModalData, setEditModalData, onClose, fetchData }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const [editAgreement, setEditAgreement] = useState(null)

	const handleEdit = (type) => {
		let newUpdatesList = agreement.updatesList.map((update, index) => {
			if (index === 0) {
				return {
					...update,
					updateDateTime: '',
					updateTextKz: '',
					updateTextRu: '',
				}
			}
			return update
		})

		const newEditAgreement = {
			...agreement,
			updatesList: newUpdatesList,
		}

		setEditAgreement(newEditAgreement)

		setEditModalData({
			type: type,
			isVisible: true
		})
	}

	const isCoefficient = agreement.industrialCoef ||
		agreement.baseSalary ||
		agreement.oneToTwoGradeCoef ||
		agreement.twoToThreeGradeCoef ||
		agreement.threeToFourGradeCoef ||
		agreement.fourToFiveGradeCoef ||
		agreement.fiveToSixGradeCoef ||
		agreement.sixToSevenGradeCoef ||
		agreement.sevenToEightGradeCoef

	return (
		<div>
			{!editModalData?.isVisible ? (
				<ModalTemplate onClose={onClose}>
					<div className={`scroll ${styles.wrapper}`}>
						<Title color="black">{t('industryAgreement.view.title')}</Title>
						{industryAgreement ? (
							<div className={cl('flex', 'gap-2')}>
								<Button iconSize={'big'} onClick={() => handleEdit('add')} buttonClassName={styles.mt20}>
									{t('industryAgreement.edit_add')}
								</Button>
								<Button iconSize={'big'} onClick={() => handleEdit('edit')} buttonClassName={styles.mt20} border={'green'} transparent icon={<Icon component={Edit}/>}>
									{t('industryAgreement.edit')}
								</Button>
							</div>
						) : null}
						<div className={styles.informationSections}>
							<section>
								<Title>{t('industryAgreement.agreement_data')}</Title>
								<GeneralData agreement={agreement}></GeneralData>
							</section>
							<InformationSection
								title={t('industryAgreement.view.tradeUnions')}
								items={agreement.tradeUnionNames}
								lang={lang}
								itemKey="tradeUnionName"
							/>
							{agreement?.employerAssociationNames ? (
								<InformationSection
									title={t('industryAgreement.view.employers')}
									items={agreement.employerAssociationNames}
									lang={lang}
									itemKey="employerAssociationName"
								/>
							) : null}
							{
								isCoefficient &&
									<section>
										<Title>{t('col_contract.different_coefficient')}</Title>
										<CoefData agreement={agreement}></CoefData>
									</section>
							}

							{agreement.extraGuarantee.length > 0 ? (
								<section>
									<Title>{t('industryAgreement.guarantee_title')}</Title>
									<GuaranteeData extraGuarantee={agreement.extraGuarantee}></GuaranteeData>
								</section>
							) : null}
							<section>
								<Title>{t('industryAgreement.table.name')}</Title>
								<TableHistory data={agreement.updatesList}></TableHistory>
							</section>
							<div className={styles.closeButton}>
								<Button onClick={onClose}>{t('close')}</Button>
							</div>
						</div>
					</div>
				</ModalTemplate>
			) : (
				<CreateOrEdit
					agreement={editAgreement}
					editModalData={editModalData}
					fetchData={fetchData}
					onClose={() => setEditModalData({type: null, isVisible: false})}
					onEditSuccess={onClose}
				></CreateOrEdit>
			)}
		</div>
	)
}
