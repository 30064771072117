import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Form, Formik, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@/components/DatePicker'
import { SelectSearch, Button, Checkbox, Title, Textarea } from '@/ui'
import { Add, Info, InfoGreen } from '@app/icons'
import { contractTerminationSchema } from './contractTermination.schema'
import styles from './ContractTerminationForm.module.scss'
import { AdditonalReasonSelect } from '@/components/AdditionalReasonSelect'
import { HarmfulCheckboxBlock } from '@features/contracts/harmfulCheckboxBlock'
import { calculateAge, updateAnnuityStatus } from '@/utils'
import { useDispatch, useSelector } from 'react-redux'
import { Rate, Tooltip } from 'antd'
import cl from 'classnames'
import { ReviewInfoWarning } from '@/features'
import { contractsThunks } from '@/entity'
import { LoadingScreen } from '@/components/Loader'
import { LANGUAGE_ENUM, useLanguage } from '@/shared'

const status = {
	'1': {
		icon: <Info className={cl(styles.iconGreen, styles.iconStatus)}/>,
		isFormVisible: true,
		preStatus: 'personal_affairs.accepted_payments'
	},
	'-1': {
		icon: <Info className={cl(styles.iconRed, styles.iconStatus)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-3': {
		icon: <Info className={cl(styles.iconRed, styles.iconStatus)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-4': {
		icon: <Info className={cl(styles.iconRed, styles.iconStatus)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-11': {
		icon: <Info className={cl(styles.iconRed, styles.iconStatus)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-12': {
		icon: <Info className={cl(styles.iconRed, styles.iconStatus)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-13': {
		icon: <Info className={cl(styles.iconRed, styles.iconStatus)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-20': {
		icon: <Info className={cl(styles.iconRed, styles.iconStatus)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
}

export const ContractTerminationForm = ({ contract, terminationReasons = [], onSubmit, onCancel, isEdit }) => {
	const {
		termReasonsList,
		terminationDate,
		additionalContracts,
		dworkingConditionsCode,
		employeeRequest,
		employeeConsent,
		employerApprove,
		employeeAccountNumber,
		birthDate,
		hrManagerPhone,
		employeePhone,
		review
	} = contract

	const dispatch = useDispatch()
	const [additionalReasons, setAdditionalReasons] = useState(isEdit ? termReasonsList.slice(1) : [])
	const { t } = useTranslation()
	const lastTerminationReasons = useMemo(() => terminationReasons.filter(({ last }) => !!last), [terminationReasons])
	const annuityDate = calculateAge(birthDate)
	const [annuity, setAnnuity] = useState(false)
	const [tempTerminationDate, setTempTerminateDate] = useState(null)
	const [checkTerminate, setCheckTerminate] = useState(false)
	const { lang } = useLanguage()

	const {isError, reviewType, oopvSendByBin} = useSelector((state) => ({
		isError: state.oppv.isError,
		reviewType: state.handbook.reviewType,
		oopvSendByBin: state.contracts.oopvSendByBin
	}))

	const currentReview = review?.find(item => item.reviewTypeCode === 'employerReview')

	useEffect(() => {
		updateAnnuityStatus(additionalContracts, dworkingConditionsCode, annuityDate, setAnnuity)

		let body = {
			data: {iin: contract.iin},
		}
		dispatch(contractsThunks.getOopvSendByBin(body))
	}, [])

	useEffect(() => {
		if (tempTerminationDate) {
			let dateParts = tempTerminationDate.split('.')
			let terminateYear = dateParts[2]
			/**
			 * Represents the beginning year of an annuity.
			 *
			 * @type {string}
			 */
			const annuityBeginYear = '2024'
			if (terminateYear >= annuityBeginYear) {
				setCheckTerminate(true)
			} else {
				setCheckTerminate(false)
			}
		}
	}, [tempTerminationDate])

	const handleAddReasonClick = useCallback(() => {
		setAdditionalReasons((value) => [...value, null])
	}, [])

	const handleRemoveReasonClick = useCallback((index) => {
		setAdditionalReasons((value) => {
			const newArr = [...value]
			newArr.splice(index, 1)
			return newArr
		})
	}, [])

	const handleDataProcess = useCallback(
		(data) => {
			const toSubmit = {
				terminationDate: data.terminationDate,
				terminationReasons: [data.terminationReason, ...additionalReasons],
				employeeRequest: checkTerminate ? data.employeeRequest : null,
				employeeConsent: checkTerminate ? data.employeeConsent : null,
				employerApprove: checkTerminate ? data.employerApprove : null,
				employeeAccountNumber: checkTerminate ? data.employeeAccountNumber : null,
				hrManagerPhone: checkTerminate ? data.hrManagerPhone : null,
				employeePhone: checkTerminate ? data.employeePhone : null,

				...((data.rating || data.reviewText) ? {
					reviewPayload: {
						...(data.rating ? {rating: data.rating,} : {}),
						...(data.reviewText ? {reviewText: data.reviewText,} : {}),
						...(currentReview ? {externalId: currentReview?.review?.externalId} : {}),
						reviewerType: 'employerReview',
					}
				} : {})
			}
			onSubmit(toSubmit)
		},
		[additionalReasons, onSubmit, checkTerminate]
	)

	const [disableContinue, setDisableContinue] = useState(false)
	const [disableHarm, setDisableHarm] = useState(false)
	const getFormatDate = (value) => {
		const dateArr = value?.split('.')
		if (dateArr) {
			return `${dateArr?.[1]}.${dateArr?.[0]}.${dateArr?.[2]}`
		} else {
			return false
		}
	}

	const dateArr = contract?.dateFrom?.split('.')

	const validateDate = (value) => {
		if (getFormatDate(value) && getFormatDate(contract?.dateFrom)) {
			const now = new Date()
			const dateFrom = new Date(getFormatDate(contract?.dateFrom))
			const valueDate = new Date(getFormatDate(value))
			setDisableContinue(valueDate > now || valueDate < dateFrom)
			setTempTerminateDate(value)
			setDisableHarm(isEdit && !checkTerminate)
		}
	}

	const ratingValue = {
		1: t('reviews.star1'),
		2: t('reviews.star2'),
		3: t('reviews.star3'),
		4: t('reviews.star4'),
		5: t('reviews.star5'),
	}

	const renderAnnuity = useMemo(() => {
		if(oopvSendByBin.loading){
			return (
				<div className={styles.loadingWrapper}>
					<LoadingScreen>
						<span>{t('personal_affairs.loading_status')}</span>
					</LoadingScreen>
				</div>
			)
		} else {
			let label = {
				[LANGUAGE_ENUM.rus]: oopvSendByBin.data?.outRes.nameRu,
				[LANGUAGE_ENUM.kz]: oopvSendByBin.data?.outRes.nameKz,
			}
			return (
				<HarmfulCheckboxBlock isEdit={isEdit} annuity={annuity} isFormVisible={status[oopvSendByBin.data?.outRes?.code]?.isFormVisible || false} status={(
					<div className={cl('mt-3', 'flex', 'align-center', 'gap-2')}>
						{status[oopvSendByBin.data?.outRes?.code]?.icon && status[oopvSendByBin.data?.outRes?.code]?.icon}
						<strong>{t('personal_affairs.status')}</strong>
						<span>{t(status[oopvSendByBin.data?.outRes?.code]?.preStatus)}. {label[lang]}</span>
					</div>
				)} />
			)
		}
	}, [oopvSendByBin])

	return (
		<>
			<Formik
				initialValues={{
					terminationDate: isEdit ? terminationDate : null,
					terminationReason: isEdit ? termReasonsList[0] : null,
					employeeRequest: isEdit ? employeeRequest : false,
					employeeConsent: isEdit ? employeeConsent : false,
					employerApprove: isEdit ? employerApprove : false,
					employeeAccountNumber: isEdit ? employeeAccountNumber : '',
					hrManagerPhone: isEdit ? hrManagerPhone : '',
					employeePhone: isEdit ? employeePhone : '',
					rating: currentReview?.rating,
					reviewText: currentReview?.review?.text,
				}}
				onSubmit={handleDataProcess}
				validationSchema={contractTerminationSchema(oopvSendByBin.data?.outRes?.code == 1)}
			>
				{({ isValid, values, setFieldValue, errors }) => {
					validateDate(values.terminationDate)
					return (
						<Form>
							<div className={styles.col}>
								<div className={styles.row}>
									<div className="flex-1">
										<label className={styles.label}>{t('terminate_date')}*</label>
										<Field name="terminationDate">
											{({ field }) => (
												<DatePicker
													minDate={dateArr ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`) : ''}
													{...field}
													showIcon
													maxDate={new Date()}
												/>
											)}
										</Field>
									</div>
									<div className="flex-2">
										<label className={styles.label}>{t('contract_termination_reason')}</label>
										<div className={styles.selectCol}>
											<div className="flex">
												<Field name="terminationReason">
													{({ field }) => (
														<SelectSearch {...field} className="flex-1" options={lastTerminationReasons} placeholder={t('contracts_tab.recover.select_from_dict')} />
													)}
												</Field>
												<Button transparent onClick={handleAddReasonClick} buttonClassName={styles.button}>
													<Add className={styles.iconGreen} />
													{t('add')}
												</Button>
											</div>
											{additionalReasons.map((item, index) => (
												<div key={`additonal-resaons-${index}`} className="flex">
													<AdditonalReasonSelect
														index={index}
														setAdditionalReasons={setAdditionalReasons}
														value={item}
														terminationReasons={terminationReasons}
														removeReason={handleRemoveReasonClick}
													/>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							{annuity ? renderAnnuity : null}

							<div className={'mt-5'}>
								<Title>
									{t('reviews.employee_review')}
								</Title>

								<div className={'my-4'}>
									<ReviewInfoWarning title={t('attention')} text={t('reviews.terminate_alert_text')}/>
								</div>

								<div className="mt-4">
									<div className={styles.row}>
										<label>
											{t('reviews.employee_rating')}
										</label>
									</div>
									<div className={styles.row}>
										<Field name="rating">
											{({ field }) => (
												<div>
													<Rate
														onChange={(value) => setFieldValue(field.name, value)}
														value={field.value}
														className={styles.rating}
													/>

													<span className={cl('ms-3')}>{ratingValue[field.value] || ''}</span>
												</div>
											)}
										</Field>
									</div>
								</div>

								<div className="mt-3">
									<div className={styles.row}>
										<label>
											{t('reviews.employee_review')}
										</label>
									</div>

									<div className={cl(styles.row, 'mt-2')}>
										<Field name="reviewText">
											{({ field }) => (
												<Textarea  {...field} placeholder={t('reviews.placeholder_text')}/>
											)}
										</Field>
									</div>
								</div>
							</div>

							<div className={styles.buttonBlock}>
								<Button transparent onClick={onCancel}>
									{t('cancel')}
								</Button>
								<Button disabled={!isValid || disableContinue || disableHarm || oopvSendByBin.loading} type="submit">
									{t('sign_ecp')}
								</Button>
							</div>
							<div className={styles.errorButton}>
								{annuity && isEdit && !checkTerminate ? (
									<div className={styles.error}>{t('error_message_button')}</div>
								) : null}
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}
