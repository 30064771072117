import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { Title, Field, Button, Checkbox } from '@/ui'
import { ConfirmDocument } from '@/components/AddDocuments/ConfirmDocument'
import { ConfirmDocument as ConfirmDocumentSingle } from '@/entity/contracts/ui/form/Documents/ConfirmDocument/ConfirmDocument'
import { ContractTerm, ContractWorkingHours, ContractJobType, ContractJobLocation, ContractOther } from './conditions'
import { ContractPosition } from '@/features/contracts/registrationEmployerContract/form/conditions/ContractPosition'
import { useDispatch, useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { handbookSelectors } from '@/entity/handbook'
import styles from './AdditionalContractForm.module.scss'
import { LANGUAGE_ENUM, usePrevious } from '@/shared'
import { WorkingConditions } from '@features/contracts/registrationEmployerContract/form/workingСonditions/workingConditions'
import { Tooltip } from 'antd'
import { Info } from '@app/icons'
import { calculateAge, isHarmfulWorkingConditions, updateAnnuityStatus } from '@/utils'
import { HarmfulCheckboxBlock } from '@features/contracts/harmfulCheckboxBlock'
import { CustomField } from './CustomField/CustomField'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { contractsThunks } from '@/entity'
import { LoadingScreen } from '@/components/Loader'
import cl from 'classnames'
import { getCurrentLanguage } from '@/i18next'

const status = {
	'1': {
		icon: <Info className={cl(styles.iconGreen)}/>,
		isFormVisible: true,
		preStatus: 'personal_affairs.accepted_payments'
	},
	'-1': {
		icon: <Info className={cl(styles.iconRed)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-3': {
		icon: <Info className={cl(styles.iconRed)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-4': {
		icon: <Info className={cl(styles.iconRed)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-11': {
		icon: <Info className={cl(styles.iconRed)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-12': {
		icon: <Info className={cl(styles.iconRed)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-13': {
		icon: <Info className={cl(styles.iconRed)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
	'-20': {
		icon: <Info className={cl(styles.iconRed)}/>,
		isFormVisible: false,
		preStatus: 'personal_affairs.not_accepted_payments'
	},
}

export const Form = ({
	setIsLocal,
	setDoc,
	showCheckbox,
	conditions,
	additionalContractTransfer,
	showFileUpload,
	contract,
	onCancelEditing,
	additionalAgreement,
	attachHarmfulConditionsFiles,
	isEdit,
}) => {
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { setFieldValue, values } = useFormikContext()
	const [isFormDirty, setFormDirty] = useState(false)
	const [disableErrorSize, setDisableErrorSize] = useState(true)
	const [checkDoc, setCheckDoc] = useState(true)
	const [documentList, setDocList] = useState([])
	const {
		positionsByKnz,
		workCondition,
		positionsByKnzFull,
		workActivityTypeGroups,
		establishedPostPositionCategoryRelation,
	} = useSelector(contractActionsSelectors.options)
	const { jobTypes, contractTerms, workingTimeModes, countries, districts, remoteWorkTypes, positions } = useSelector(
		handbookSelectors.all
	)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)

	const {oopvSendByBin} = useSelector((state) => ({
		oopvSendByBin: state.contracts.oopvSendByBin
	}))
	const lmc = activeCompany?.lmc
	const filtredPositions = positions?.filter((item) => item.isLast && (!lmc || item.lmc))
	const { dworkingConditionsCode, dateFrom } = values
	const annuityDate = calculateAge(contract?.birthDate)
	const [annuity, setAnnuity] = useState(false)
	const [activeHarmfulCheckbox, setActiveHarmfulCheckbox] = useState(false)
	const [checkDateFrom, setCheckDateFrom] = useState(false)
	const getDocumentHarmful = (docList) => {
		setDocList(docList)
		attachHarmfulConditionsFiles(docList)
	}
	const onChangeIsLocal = (_isLocal, data) => {
		setIsLocal(_isLocal)
		setFormDirty(true)
		Boolean(positionsByKnz.find(({ code }) => code === data?.dpositionCode))
	}
	const getDocumentSingle = async (doc) => {
		if (doc) setDoc(doc)
	}
	const prevValue = usePrevious(dateFrom)
	useEffect(() => {
		let body = {
			data: {iin: contract.iin},
		}
		dispatch(contractsThunks.getOopvSendByBin(body))
	}, [])
	useEffect(() => {
		if (!!contract && checkDateFrom) {
			let additionalContracts = contract?.additionalContracts
			updateAnnuityStatus(additionalContracts, contract?.dworkingConditionsCode, annuityDate, setAnnuity)
		} else {
			setAnnuity(false)
		}
	}, [checkDateFrom])
	const shouldBeActive = () => {
		const baseCondition = annuity && dworkingConditionsCode === '0' && !!dworkingConditionsCode

		return baseCondition || (baseCondition && isEdit && additionalAgreement.dcontractState !== '1')
	}

	useEffect(() => {
		setActiveHarmfulCheckbox(shouldBeActive())
	}, [annuity, dworkingConditionsCode])

	useEffect(() => {
		if (prevValue) {
			setFieldValue('positionParents', '')
			setFieldValue('dpositionCode', null)
		}
		if (dateFrom) {
			let dateParts = dateFrom.split('.')
			let terminateYear = dateParts[2]
			/**
			 * Represents the beginning year of an annuity.
			 *
			 * @type {string}
			 */
			const annuityBeginYear = '2024'
			if (terminateYear >= annuityBeginYear) {
				setCheckDateFrom(true)
			} else {
				setCheckDateFrom(false)
				setFieldValue('employeeRequest', null)
				setFieldValue('employeeConsent', null)
				setFieldValue('employerApprove', null)
				setFieldValue('employeeAccountNumber', null)
				setFieldValue('employeeIbanRequest', null)
			}
		}
	}, [dateFrom])
	const getErrorSize = (getErrorSize) => {
		setDisableErrorSize(getErrorSize)
	}

	useEffect(() => {
		if (dworkingConditionsCode && dworkingConditionsCode !== '0') {
			setCheckDoc(documentList.length >= 1 && !disableErrorSize)
		} else {
			setCheckDoc(true)
		}
	}, [documentList, disableErrorSize, dworkingConditionsCode])

	const renderAnnuity = useMemo(() => {
		if(oopvSendByBin.loading){
			return (
				<div className={styles.loadingWrapper}>
					<LoadingScreen>
						<span>{t('personal_affairs.loading_status')}</span>
					</LoadingScreen>
				</div>
			)
		} else {
			let label = {
				[LANGUAGE_ENUM.rus]: oopvSendByBin.data?.outRes.nameRu,
				[LANGUAGE_ENUM.kz]: oopvSendByBin.data?.outRes.nameKz,
			}
			return (
				<HarmfulCheckboxBlock additionalContract={true} isFormVisible={status[oopvSendByBin.data?.outRes?.code]?.isFormVisible || false} status={(
					<div className={cl('mt-3', 'flex', 'align-center', 'gap-2')}>
						{status[oopvSendByBin.data?.outRes?.code]?.icon && status[oopvSendByBin.data?.outRes?.code]?.icon}
						<strong>{t('personal_affairs.status')}</strong>
						<span>{t(status[oopvSendByBin.data?.outRes?.code]?.preStatus)}. {label[lang]}</span>
					</div>
				)} />
			)
		}
	}, [oopvSendByBin])

	return (
		<FormikForm className={styles.form}>
			<div>
				<div className={styles.col1}>
					<div className={styles.row}>
						<div className="flex-2">
							<CustomField
								name="contractNum"
								label="additional_contract_number"
								placeholder="set_additional_contract_number"
								isFormDirty={isFormDirty}
							/>
						</div>
						<div className="flex-2">
							<CustomField
								name="registerDate"
								label="reg_date_additional_contract"
								placeholder="default_date_value"
								fieldType="datePicker"
								maxDate={new Date()}
								isFormDirty={isFormDirty}
							/>
						</div>
						<div className="flex-1" />
					</div>
					<div className={styles.row}>
						<div className="flex-2">
							<CustomField
								name="dateFrom"
								label="start_date_additional_contract"
								placeholder="default_date_value"
								fieldType="datePicker"
								isFormDirty={isFormDirty}
							/>
						</div>
						<div className="flex-2">
							<FormikField name="dateTo">
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType="datePicker"
										label={t('end_date_additional_contract')}
										placeholder={t('default_date_value')}
										error={(touched || isFormDirty) && error}
										{...field}
									/>
								)}
							</FormikField>
						</div>
						<div className="flex-1" />
					</div>
				</div>
				<Title>{t('new_conditions')}</Title>

				{(showCheckbox || conditions) && additionalContractTransfer && (
					<div className={styles.checkbox__wrapper}>
						<Checkbox label={t('change_iin_bin')} value={true} disabled />
					</div>
				)}
				{conditions?.contractTerm && <ContractTerm contractTerms={contractTerms} isFormDirty={isFormDirty} />}
				{conditions?.contractWorkingHours && (
					<ContractWorkingHours workingTimeModes={workingTimeModes} isFormDirty={isFormDirty} />
				)}
				{conditions?.contractPosition && (
					<>
						<Title className={styles.title}>{t('change_contract_position')}</Title>
						<ContractPosition
							positions={filtredPositions}
							positionsByKnz={positionsByKnz}
							workCondition={workCondition}
							positionsByKnzFull={positionsByKnzFull}
							establishedPostPositionCategoryRelation={establishedPostPositionCategoryRelation}
							isFormDirty={isFormDirty}
							isElectronicButton={false}
							lmc={lmc}
							isEdit={isEdit}
						/>
						<div className={`${styles.fields} ${styles.threeFields}`}>
							<WorkingConditions isFormDirty={isFormDirty} workActivityTypeGroups={workActivityTypeGroups} />
						</div>
						{dworkingConditionsCode && dworkingConditionsCode !== '0' && !contract.econtractFound ? (
							<section>
								<div className={styles.labelHint}>
									<Title>{t('dangerous_working_document')}</Title>
									<Tooltip
										placement="rightTop"
										color="white"
										overlayInnerStyle={{ color: '#5B6880', width: '320px' }}
										title={t('download_button_hint')}
									>
										<span>
											<Info className={styles.hintIcon} />
										</span>
									</Tooltip>
								</div>
								<ConfirmDocument
									getDocument={getDocumentHarmful}
									getErrorSize={getErrorSize}
									fileType={'HARMFULWORK'}
									fileList={values.files}
								></ConfirmDocument>
							</section>
						) : null}
					</>
				)}
				{conditions?.contractJobType && <ContractJobType jobTypes={jobTypes} isFormDirty={isFormDirty} />}
				{conditions?.contractJobLocation && (
					<ContractJobLocation
						countries={countries}
						districts={districts}
						remoteWorkTypes={remoteWorkTypes}
						isFormDirty={isFormDirty}
					/>
				)}
				{conditions?.contractOther && <ContractOther isFormDirty={isFormDirty} />}
			</div>
			{showFileUpload ? (
				<ConfirmDocumentSingle
					getDocument={getDocumentSingle}
					getErrorSize={getErrorSize}
					buttonText={t('add_file_eds')}
					buttonReplace={t('replace_file_eds')}
					bottomText={t('file_info_size')}
				></ConfirmDocumentSingle>
			) : null}
			{activeHarmfulCheckbox ? (
				<div className="mb-24">
					{renderAnnuity}
				</div>
			) : null}
			{conditions ? (
				<>
					{contract.econtractFound ? (
						<div className={styles.footer}>
							<Button buttonClassName={styles.button} type="bare" onClick={onCancelEditing}>
								{t('cancel')}
							</Button>
							<Button type="submit">{t('continue')}</Button>
						</div>
					) : (
						<div className={styles.footer}>
							<Button buttonClassName={styles.button} type="bare" onClick={onCancelEditing}>
								{t('cancel')}
							</Button>
							{!additionalContractTransfer && (
								<Button
									border
									borderColor={'default'}
									textColor={'default'}
									backgroundColor={'white'}
									onClick={() => onChangeIsLocal(true, values)}
									type={'submit'}
									disabled={additionalAgreement?.dcontractState?.id === 1 || false || !checkDoc}
								>
									{t('save')}
								</Button>
							)}
							<Button
								disabled={contract?.isLocalContract || false || !checkDoc}
								type="submit"
								onClick={() => onChangeIsLocal(false, values)}
							>
								{t('save_and_sign')}
							</Button>
						</div>
					)}
				</>
			) : null}
		</FormikForm>
	)
}
