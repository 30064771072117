import { handbookSelectors } from '@/entity/handbook/index'
import { createSelector } from 'reselect'
export const contract = (state) => state.contracts.contract.data
export const stateCode = (state) => state.contracts.contract.data.dContractState
export const statusId = (state) => state.contracts.contract.data.dContractStatus
export const packageLoading = (state) => state.contracts.packageLoading
export const isLoading = (state) => state.contracts.isLoading
export const error = (state) => state.contracts.error
export const isSuccess = (state) => state.contracts.isSuccess
export const editSuccess = (state) => state.contracts.editSuccess
export const contracts = (state) => state.contracts.contracts
export const totalElements = (state) => state.contracts.totalElements
export const createContractXML = (state) => state.contracts.createContractXML
export const removeXML = (state) => state.contracts.removeXML
export const editXML = (state) => state.contracts.editXML
export const contractData = (state) => state.contracts
export const isContractLoading = (state) => state.isContractLoading
export const terminationReasons = (state) => state.contracts.terminationReasons
export const terminationDate = (state) => state.contracts.params.terminationDate
export const additionalContractIsLoading = (state) => state.contracts.additionalContract.isLoading
export const additionalContractXml = (state) => state.contracts.additionalContract.xml
export const additionalContractSubmitted = (state) => state.contracts.additionalContract.submitted
export const additionalContract = (state) => state.contracts.additionalContract.contract
export const contractsCard = (state) => state.contracts.card.submitted
export const socialLeaveXml = (state) => state.contracts.socialLeaveXML
export const socialLeaveIsLoading = (state) => state.contracts.socialLeaveIsLoading
export const socialLeaveIsSuccess = (state) => state.contracts.socialLeaveIsSuccess
export const prolongationIsSuccess = (state) => state.contracts.prolongationIsSuccess
export const prolongationIsLoading = (state) => state.contracts.prolongationIsLoading
export const prolongationXML = (state) => state.contracts.prolongationXML
export const terminationXML = (state) => state.contracts.terminationXML
export const terminationIsLoading = (state) => state.contracts.terminationIsLoading
export const terminationIsSuccess = (state) => state.contracts.terminationIsSuccess
export const contractIsLoading = (state) => state.contracts.contract.isLoading
export const contractIsSuccess = (state) => state.contracts.contract.isSuccess
export const contractHasError = (state) => state.contracts.contract.hasError
export const reorganizeIsSuccess = (state) => state.contracts.reorganize.isSuccess
export const imageEtd = (state) => state.contracts.etd.image
export const imageKey = (state) => state.contracts.etd.key
export const errorEtd = (state) => state.contracts.etd.error
export const isLoadingEtd = (state) => state.contracts.etd.isLoading
export const isSuccessEtd = (state) => state.contracts.etd.isSuccess
export const statusEtd = (state) => state.contracts.etd.status
export const oopvSendByBin = (state) => state.contracts.oopvSendByBin
export const options = createSelector(
	[
		handbookSelectors.contractTerms,
		handbookSelectors.positionsByKnz,
		handbookSelectors.positionsByKnzFull,
		handbookSelectors.positions,
		handbookSelectors.jobTypes,
		handbookSelectors.workingTimeModes,
		handbookSelectors.countries,
		handbookSelectors.regions,
		handbookSelectors.districts,
		handbookSelectors.populatedAreas,
		handbookSelectors.professionalSkills,
		handbookSelectors.personalQualities,
		handbookSelectors.remoteWorkTypes,
		handbookSelectors.contractTypes,
		handbookSelectors.workActivityTypes,
		handbookSelectors.workCondition,
		handbookSelectors.extraGuaranteesCollectiveContract,
		handbookSelectors.unionTypeCollectiveContract,
		handbookSelectors.workActivityTypeGroups,
	],
	(
		contractTerms,
		positionsByKnz,
		positionsByKnzFull,
		positions,
		jobTypes,
		workingTimeModes,
		countries,
		regions,
		districts,
		populatedAreas,
		professionalSkills,
		personalQualities,
		remoteWorkTypes,
		contractTypes,
		workActivityTypes,
		workCondition,
		extraGuaranteesCollectiveContract,
		unionTypeCollectiveContract,
		workActivityTypeGroups
	) => {
		return {
			contractTerms,
			positionsByKnz,
			positionsByKnzFull,
			positions,
			jobTypes,
			workingTimeModes,
			countries,
			regions,
			districts,
			populatedAreas,
			professionalSkills,
			personalQualities,
			remoteWorkTypes,
			contractTypes,
			workActivityTypes,
			workCondition,
			extraGuaranteesCollectiveContract,
			unionTypeCollectiveContract,
			workActivityTypeGroups,
		}
	}
)
