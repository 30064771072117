import * as types from './types'
export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setAgreement: (payload) => ({
		type: types.SET_AGREEMENT,
		payload,
	}),
	setAgreementLoading: (payload) => ({
		type: types.SET_AGREEMENT_LOADING,
		payload,
	}),
	setAgreementSuccess: (payload) => ({
		type: types.SET_AGREEMENT_SUCCESS,
		payload,
	}),
	setAgreements: (payload) => ({
		type: types.SET_AGREEMENTS,
		payload,
	}),
	setAgreementXml: (payload) => ({
		type: types.SET_AGREEMENT_XML,
		payload,
	}),
	resetAgreementXml: () => ({
		type: types.RESET_AGREEMENT_XML,
	}),
	setAgreementError: (payload) => ({
		type: types.SET_AGREEMENT_ERROR,
		payload,
	}),


	getAgreementByBin: () => ({
		type: types.GET_AGREEMENT_BY_BIN,
	}),
	getAgreementByBinDone: (payload) => ({
		type: types.GET_AGREEMENT_BY_BIN_DONE,
		payload,
	}),
	getAgreementByBinFail: () => ({
		type: types.GET_AGREEMENT_BY_BIN_FAIL,
	}),
}
