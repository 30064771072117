import styles from './Form.module.scss'
import classNames from 'classnames'
import { Field as FormikField, FieldArray, Form as FormikForm, useFormikContext } from 'formik'
import { Button, DateTimePicker, Error, Field, Label } from '@/ui'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo, useState } from 'react'
import { filterKato } from '@/entity/handbook/thunks'
import { Victim } from '@/entity/accidents/ui/form/components/Victim'
import { SelectPerson } from '@/components/SelectPerson/SelectPerson'
import { useSelector } from 'react-redux'
import { Info } from '@app/icons'
import { Tooltip } from 'antd'
import { LANGUAGE_ENUM } from '@/shared'
import { getCurrentLanguage } from '@/i18next'

const FORM_NAME = {
	injureds: 'injureds',
	senderEstablisedPost: 'senderEstablisedPost',
	code: 'code',
	nameKz: 'nameKz',
	nameRu: 'nameRu',
	accidentDateTime: 'accidentDateTime',
	region: 'region',
	district: 'district',
	location: 'location',
	circumstance: 'circumstance',
	senderEmail: 'senderEmail',
	senderPhoneNumber: 'senderPhoneNumber',
	senderFio: 'senderFio',
	senderIin: 'senderIin',

	//локальный стест чтоб знать, есть ли контракт у персоны
	isContract: 'isContract',
	key: 'key',
	isValid: 'isValid',
}

export const Form = ({ onClose }) => {
	const { t } = useTranslation()
	const { setFieldValue, values, validateForm, errors, setValues, setTouched, touched } = useFormikContext()
	const [regions, setRegions] = useState([])
	const lang = getCurrentLanguage()

	const [localPositions, setLocalPositions] = useState(null)

	const { positions, person, districts } = useSelector((state) => ({
		positions: state.handbook.positions?.map(i => ({...i, key: i.code})),
		person: state.accidents.person,
		districts: state.handbook.districts,
	}))

	const loadRegions = useCallback(async (code) => {
		if (code) {
			const response = await filterKato(code)
			setRegions(response)
		} else {
			setRegions([])
		}
	}, [])

	const handleOnSelectedPerson = (values = null, onSuccess = (values, isContract) => {}) => {
		if (values?.contractDatas) {
			setLocalPositions(values.contractDatas?.map(i => ({...i, establishedPost: {...i.establishedPost, kz: i.establishedPost?.nameKz, rus: i.establishedPost?.nameRu}})))
		}

		onSuccess(values, !!values?.contractDatas)
	}

	const handleChange = (key = '', value = '', onSuccess = (contractItem) => {}) => {
		switch (key) {
			case FORM_NAME.establishedPost:
				if(localPositions){
					let find = localPositions?.find(i => i.establishedPost?.key === value)

					if(find){
						onSuccess(find)
					}
				}
				break

			default:
				break
		}
	}

	const positionsOptions = useCallback((bool) => {
		let res = bool ? localPositions?.map(i => i?.establishedPost) : positions

		return res
	}, [localPositions, positions])

	return (
		<FormikForm className={styles.wrapper}>
			<div className={styles.chapter}>
				<div className={styles.row}>
					<section className={classNames(styles.fields, styles.fields_threeFields)}>
						<FormikField name={FORM_NAME.accidentDateTime}>
							{({ meta: { touched, error } }) => (
								<div className={styles.dateTimeField}>
									<Label name="accidentDate">{t('accidentsPage.register.accidentDate') + ' *'}</Label>
									<DateTimePicker
										onChange={(value, dateString) => {
											setFieldValue(FORM_NAME.accidentDateTime, dateString)
										}}
										required
									/>
									{(touched && error) && (
										<Error>
											{typeof error === 'string' ? error : t(`validation.${error.key}`, { value: error.value })}
										</Error>
									)}
								</div>
							)}
						</FormikField>
						<FormikField name={FORM_NAME.region}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									options={districts}
									type="text"
									fieldType="select"
									label={t('region')}
									placeholder={t('accidentsPage.register.selectRegion')}
									isRequired
									disabled={false}
									error={touched && error}
									onChange={(code) => {
										setFieldValue('region', code)
										setFieldValue('district', null)
										loadRegions(code)
									}}
								/>
							)}
						</FormikField>
						<FormikField name={FORM_NAME.district}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									options={regions}
									type="text"
									fieldType="select"
									label={t('district')}
									isRequired
									placeholder={t('accidentsPage.register.selectDistrict')}
									disabled={false}
									error={touched && error}
								/>
							)}
						</FormikField>
					</section>
					<section>
						<FormikField name={FORM_NAME.location}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									type="text"
									fieldType="textarea"
									label={t('accidentsPage.register.incidentScene')}
									isRequired
									rows={4}
									placeholder={t('accidentsPage.register.incidentScene')}
									error={touched && error}
									hint={t('accidentsPage.register.incidentSceneHint')}
								/>
							)}
						</FormikField>
					</section>
					<section>
						<FormikField name={FORM_NAME.circumstance}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									labelClassName={styles.formLabel}
									type="text"
									isRequired
									fieldType="textarea"
									label={t('accidentsPage.register.circumstances')}
									placeholder={t('specification')}
									rows={4}
									disabled={false}
									error={touched && error}
									autosize
								/>
							)}
						</FormikField>
					</section>
				</div>
				<div className={styles.row}>
					<Victim/>
				</div>
				<div className={styles.row}>
					<div>
						<Tooltip placement="top" color="white" overlayInnerStyle={{ color: '#5B6880'}} title={t('accidentsPage.boss')}>
							<h5 className={'me-2'}>{t('accidentsPage.register.applicant')}</h5>
							<Info className={styles.hint}/>
						</Tooltip>
					</div>
					<SelectPerson
						wrapperClassName={styles.senderSelectPersonWrapper}
						iinLabel={t('accidentsPage.senderIin')}
						iinFieldName={'senderIin'}
						fullNameFieldName={'senderFio'}
						onSelected={(personValues) => handleOnSelectedPerson(personValues, (personData, isContract) => {
							let res = {
								...values,
								[FORM_NAME.isContract]: !!isContract,
								[FORM_NAME.senderIin]: personData?.iin,
								[FORM_NAME.senderFio]: personData?.fio,
								[FORM_NAME.isValid]: true,
							}
							setValues(res)
						})}
						onChange={(value) => {
							setFieldValue(FORM_NAME.isValid, false)
							setFieldValue(FORM_NAME.senderFio, false)
						}}
					/>
					<section>
						<FormikField name={`${FORM_NAME.senderEstablisedPost}.${FORM_NAME.code}`}>
							{({ field, meta: { touched, error } }) => {
								let value = {
									[LANGUAGE_ENUM.rus]: values?.[FORM_NAME.senderEstablisedPost]?.[FORM_NAME.nameRu],
									[LANGUAGE_ENUM.kz]: values?.[FORM_NAME.senderEstablisedPost]?.[FORM_NAME.nameKz],
								}

								return (
									<Field
										{...field}
										options={positionsOptions(values?.[FORM_NAME.isContract]) || []}
										fieldType="selectSearch"
										isRequired
										label={t('contract.info.position')}
										placeholder={t('position_placeholder')}
										error={touched && error}
										optionObjectKey={FORM_NAME.key}
										value={value?.[lang]}
										onChange={(value) => {
											let array = positionsOptions(values?.[FORM_NAME.isContract]) || []
											let find = array?.find(i => i.key === value)

											let res = {
												...values,
												[FORM_NAME.senderEstablisedPost]: {
													[FORM_NAME.code]: find?.code,
													[FORM_NAME.nameKz]: find?.kz,
													[FORM_NAME.nameRu]: find?.rus,
												}
											}

											setValues(res)

											handleChange('establishedPost', value, (contractItem) => {
												if (contractItem) {
													res = {
														...res,
														[FORM_NAME.isContract]: true
													}

													setValues(res)
												}
											})
										}}
										disabled={person.loading}
									/>
								)
							}}
						</FormikField>
					</section>
					<section>
						<FormikField name={FORM_NAME.senderPhoneNumber}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									type="number"
									min={0}
									hideNumberArrows
									enablecheckfloat
									isRequired
									label={t('contactPhone')}
									placeholder={t('contactPhonePlaceholder')}
									disabled={false}
									error={touched && error}
								/>
							)}
						</FormikField>
					</section>
					<section>
						<FormikField name={FORM_NAME.senderEmail}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									type="text"
									fieldType="input"
									isRequired
									label={t('electronic_mail')}
									placeholder={t('electronic_mail')}
									disabled={false}
									error={touched && error}
								/>
							)}
						</FormikField>
					</section>
				</div>
			</div>

			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button
					type="submit"
					onClick={() => {
						validateForm().then(err => {
							let res = JSON.parse(JSON.stringify(err, (key, value) => {
								if (value?.key === "required" || typeof value == 'string') {
									return true;
								}
								return value;
							}))

							setTouched(res)
						})
					}}
				>
					{t('sign_and_send')}
				</Button>
			</div>
		</FormikForm>
	)
}
