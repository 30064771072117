import { connect, Field as FormikField, FieldArray } from 'formik'
import { SwitchBox } from '@/ui/atoms/switchBox'
import { SelectPerson } from '@/components/SelectPerson/SelectPerson'
import styles from '@features/accidents/steps/accidentReport/Form.module.scss'
import { Button, Field } from '@/ui'
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Plus } from '@app/images'
import { useSelector } from 'react-redux'
import { LANGUAGE_ENUM } from '@/shared'
import { getCurrentLanguage } from '@/i18next'

let FORM_NAME = {
	injureds: 'injureds',
	isResident: 'isResident',
	iin: 'iin',
	fio: 'fio',
	surname: 'surname',
	firstname: 'firstname',
	secondname: 'secondname',
	birthDate: 'birthDate',
	gender: 'gender',
	passportNumber: 'passportNumber',
	issuredBy: 'issuredBy',
	issuredWhen: 'issuredWhen',
	establishedPost: 'establishedPost',
	positionCode: 'positionCode',
	diagnosis: 'diagnosis',
	code: 'code',
	nameKz: 'nameKz',
	nameRu: 'nameRu',
	positionNameKz: 'positionNameKz',
	positionNameRu: 'positionNameRu',
	icd: 'icd',

	//локальный стест чтоб знать, есть ли контракт у персоны
	isContract: 'isContract',
	//локальный стест для уникальности
	key: 'key',
	isValid: 'isValid',
}

export const Victim = connect(
	({
		 formik: { setFieldValue, values, resetForm, setValues },
	 }) => {
		const { t } = useTranslation()
		const lang = getCurrentLanguage()

		const { genders, positionsByKnz, positions, person, icd } = useSelector((state) => ({
			genders: state.handbook.genders,
			positionsByKnz: state.handbook.positionsByKnz,
			positions: state.handbook.positions?.map(i => ({ ...i, key: i.code })),
			person: state.accidents.person,
			icd: state.handbook.icd,
		}))

		const [localPositions, setLocalPositions] = useState(null)

		let validateSurname = (item) => {
			let error;
			if (!(!!item.surname || !!item.secondname || !!item.firstname)) {
				error = t('accidentsPage.fio_condition_error');
			}
			return error;
		}

		return (
			<>
				<FieldArray name={FORM_NAME.injureds}>
					{({ push, remove }) => {
						return (
							<>
								{values.injureds?.map((item, index) => {
									return (
										<Fragment key={index}>
											<section>
												<h5>{t('accidentsPage.register.victim') + ' ' + (values.injureds?.length > 1 ? index + 1 : '')} </h5>

												<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.isResident}`}>
													{({ field }) => (
														<SwitchBox
															{...field}
															disabled={false}
															label={t('accidentsPage.register.resident')}
															onChange={(value) => {
																field.onChange(value)
																setLocalPositions(null)

																let res = {
																	...values,
																	injureds: values.injureds?.map((injured, injuredIdx) => {
																		if (injuredIdx === index) {
																			return {
																				[FORM_NAME.isResident]: value,
																			}
																		} else {
																			return injured
																		}
																	}),
																}
																setValues(res)
															}}
														/>
													)}
												</FormikField>

												{
													values.injureds?.length > 1 &&
													<Button buttonClassName={'float-end'} type="bare" onClick={() => remove(index)}>
														{t('remove')}
													</Button>
												}
											</section>

											{item.isResident ?
												(
													<section className={styles.victimSection_gender}>
														<SelectPerson
															wrapperClassName={styles.selectPersonWrapper}
															iinLabel={t('accidentsPage.victimIin')}
															iinFieldName={`${FORM_NAME.injureds}.${index}.${FORM_NAME.iin}`}
															fullNameFieldName={`${FORM_NAME.injureds}.${index}.${FORM_NAME.fio}`}
															onChange={(value) => {
																setFieldValue(`${FORM_NAME.injureds}.${index}.${FORM_NAME.isValid}`, false)
																setFieldValue(`${FORM_NAME.injureds}.${index}.${FORM_NAME.fio}`, false)
																setFieldValue(`${FORM_NAME.injureds}.${index}.${FORM_NAME.firstname}`, false)
																setFieldValue(`${FORM_NAME.injureds}.${index}.${FORM_NAME.secondname}`, false)
																setFieldValue(`${FORM_NAME.injureds}.${index}.${FORM_NAME.surname}`, false)
															}}
															onSelected={(personValues) => {
																setLocalPositions(prev => ({
																	...prev,
																	[index]: personValues?.contractDatas,
																}))

																let res = {
																	...values,
																	injureds: values.injureds?.map((injured, injuredIdx) => {
																		if (injuredIdx === index) {
																			return {
																				[FORM_NAME.isResident]: injured.isResident,
																				[FORM_NAME.isContract]: !!personValues?.contractDatas,
																				[FORM_NAME.iin]: personValues?.iin,
																				[FORM_NAME.fio]: personValues?.fio,
																				[FORM_NAME.firstname]: personValues?.firstname,
																				[FORM_NAME.secondname]: personValues?.secondname,
																				[FORM_NAME.surname]: personValues?.surname,
																				[FORM_NAME.isValid]: true,
																			}
																		} else {
																			return injured
																		}
																	}),
																}
																setValues(res)
															}}
														/>
														<div className={styles.ml}>
															<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.gender}`}>
																{({ field, meta: { touched, error } }) => (
																	<Field
																		{...field}
																		fieldType="select"
																		options={genders || []}
																		isRequired
																		label={t('gender')}
																		placeholder={t('gender')}
																		error={touched && error}
																	/>
																)}
															</FormikField>
														</div>
													</section>
												) :
												(
													<>
														<section className={styles.victimNonResidentDocuments}>
															<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.surname}`} validate={() => validateSurname(item)}>
																{({ field, meta: { touched, error } }) => (
																	<Field
																		{...field}
																		type="text"
																		fieldType="input"
																		label={t('accidentsPage.victimSurname')}
																		placeholder={t('accidentsPage.placeholderSurname')}
																		error={error}
																	/>
																)}
															</FormikField>
															<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.firstname}`}>
																{({ field, meta: { touched, error } }) => (
																	<Field
																		{...field}
																		type="text"
																		fieldType="input"
																		label={t('accidentsPage.victimFirstname')}
																		placeholder={t('accidentsPage.placeholderFirstname')}
																	/>
																)}
															</FormikField>
															<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.secondname}`}>
																{({ field, meta: { touched, error } }) => (
																	<Field
																		{...field}
																		type="text"
																		fieldType="input"
																		label={t('accidentsPage.victimSecondName')}
																		placeholder={t('accidentsPage.placeholderSecondName')}
																		error={touched && error}
																	/>
																)}
															</FormikField>
														</section>
														<section className={styles.victimNonResidentDocuments}>
															<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.birthDate}`}>
																{({ field, meta: { touched, error } }) => (
																	<Field
																		{...field}
																		style={{ width: '100%' }}
																		fieldType="datePicker"
																		label={t('accidentsPage.register.birthday')}
																		placeholder={t('default_date_value')}
																		isRequired
																		maxDate={new Date()}
																		error={touched && error}
																	/>
																)}
															</FormikField>
															<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.gender}`}>
																{({ field, meta: { touched, error } }) => (
																	<Field
																		{...field}
																		fieldType="select"
																		options={genders || []}
																		isRequired
																		label={t('gender')}
																		placeholder={t('gender')}
																		error={touched && error}
																	/>
																)}
															</FormikField>
														</section>
														<section className={styles.victimNonResidentDocuments}>
															<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.passportNumber}`}>
																{({ field, meta: { touched, error } }) => (
																	<Field
																		{...field}
																		type="text"
																		fieldType="input"
																		label={t('accidentsPage.register.passport')}
																		isRequired
																		placeholder={t('accidentsPage.register.number')}
																		error={touched && error}
																	/>
																)}
															</FormikField>
															<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.issuredBy}`}>
																{({ field, meta: { touched, error } }) => (
																	<Field
																		{...field}
																		type="text"
																		fieldType="input"
																		label={t('accidentsPage.register.issuer')}
																		isRequired
																		placeholder={t('accidentsPage.register.issuerName')}
																		error={touched && error}
																	/>
																)}
															</FormikField>
															<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.issuredWhen}`}>
																{({ field, meta: { touched, error } }) => (
																	<Field
																		{...field}
																		style={{ width: '100%' }}
																		fieldType="datePicker"
																		label={t('accidentsPage.register.takenDate')}
																		placeholder={t('default_date_value')}
																		isRequired
																		maxDate={new Date()}
																		error={touched && error}
																	/>
																)}
															</FormikField>
														</section>
													</>
												)
											}

											<section>
												<FormikField
													name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.establishedPost}.${FORM_NAME.key}`}>
													{({ field, meta: { touched, error } }) => {
														let value = {
															[LANGUAGE_ENUM.kz]: values?.[FORM_NAME.injureds]?.[index]?.[FORM_NAME.establishedPost]?.[FORM_NAME.nameKz],
															[LANGUAGE_ENUM.rus]: values?.[FORM_NAME.injureds]?.[index]?.[FORM_NAME.establishedPost]?.[FORM_NAME.nameRu],
														}
														return (
															<Field
																{...field}
																options={item.isContract ? (localPositions?.[index]?.map(i => i.establishedPost) || []) : positions}
																fieldType="selectSearch"
																isRequired
																label={t('contract.info.position')}
																placeholder={t('position_placeholder')}
																error={touched && error}
																optionObjectKey={FORM_NAME.key}
																value={value?.[lang]}
																onChange={(value) => {
																	let array = item.isContract ? (localPositions?.[index]?.map(i => i.establishedPost) || []) : positions
																	let findEstablishedPost = array?.find(i => i.key === value)

																	let res = {
																		...values,
																		injureds: values.injureds?.map((injured, injuredIdx) => {
																			if (injuredIdx === index) {
																				return {
																					...injured,
																					[FORM_NAME.establishedPost]: {
																						[FORM_NAME.nameKz]: findEstablishedPost?.kz,
																						[FORM_NAME.nameRu]: findEstablishedPost?.rus,
																						[FORM_NAME.code]: findEstablishedPost?.code,
																					},
																				}
																			} else {
																				return injured
																			}
																		}),
																	}

																	setValues(res)

																	let contractItem = localPositions?.[index]?.find(i => i.establishedPost?.key === value)

																	if (contractItem) {
																		let findPositionsByNkz = positionsByKnz?.find(i => i.code === contractItem?.positionCode)

																		res = {
																			...res,
																			injureds: res.injureds?.map((injured, injuredIdx) => {
																				if (injuredIdx === index) {
																					return {
																						...injured,
																						[FORM_NAME.positionCode]: contractItem?.positionCode,
																						[FORM_NAME.isContract]: true,
																						[FORM_NAME.positionNameKz]: findPositionsByNkz?.kz,
																						[FORM_NAME.positionNameRu]: findPositionsByNkz?.rus,
																					}
																				} else {
																					return injured
																				}
																			}),
																		}

																		setValues(res)
																	}
																}}
																disabled={person.loading}
															/>
														)
													}}
												</FormikField>
											</section>
											<section>
												<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.positionCode}`}>
													{({ field, meta: { touched, error } }) => (
														<Field
															{...field}
															options={positionsByKnz}
															type="text"
															fieldType="selectSearch"
															isRequired
															label={t('contract.info.nkz_position')}
															placeholder={t('accidentsPage.register.positionNkz')}
															error={touched && error}
															disabled={(person.loading) || item?.isContract}
															onChange={(value) => {
																field.onChange(value)
																let findPositionsByNkz = positionsByKnz?.find(i => i.code === value)

																let res = {
																	...values,
																	injureds: values.injureds?.map((injured, injuredIdx) => {
																		if (injuredIdx === index) {
																			return {
																				...injured,
																				[FORM_NAME.positionCode]: findPositionsByNkz?.code,
																				[FORM_NAME.positionNameKz]: findPositionsByNkz?.kz,
																				[FORM_NAME.positionNameRu]: findPositionsByNkz?.rus,
																			}
																		} else {
																			return injured
																		}
																	}),
																}

																setValues(res)
															}}
														/>
													)}
												</FormikField>
											</section>
											<section>
												<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.diagnosis}`}>
													{({ field, meta: { touched, error } }) => (
														<Field
															{...field}
															type="text"
															fieldType="input"
															isRequired
															label={t('accidentsPage.register.icd10NameProf')}
															placeholder={t('accidentsPage.register.selectIcd10')}
															disabled={false}
															error={touched && error}
														/>
													)}
												</FormikField>
											</section>
											<section>
												<FormikField name={`${FORM_NAME.injureds}.${index}.${FORM_NAME.icd}`}>
													{({ field, meta: { touched, error } }) => (
														<Field
															{...field}
															options={icd}
															type="text"
															fieldType="selectSearch"
															label={t('accidentsPage.icd')}
															placeholder={t('choose_from_directory')}
															error={touched && error}
														/>
													)}
												</FormikField>
											</section>
										</Fragment>
									)
								})}

								<section>
									<Button
										textColor="green"
										borderColor="green"
										transparent
										border
										type="button"
										icon={Plus}
										onClick={() => push({ isResident: true })}
									>
										{t('accidentsPage.register.addVictim')}
									</Button>
								</section>
							</>
						)
					}}
				</FieldArray>
			</>
		)
	},
)
